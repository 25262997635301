/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ConfigureAmplify from '../../common/awsConfig ';
import { Auth } from 'aws-amplify';
import request from '../../services/AxiosInstance';
import { toast } from 'react-toastify';
import { sha256 } from "js-sha256"
import logo from "../../images/ilocalboxlogo.png"
import { BtnLoader } from '../components/bootstrap/Loader';

function ForgotPassword() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState('');
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [confirmPassword, setConfirmpassword] = useState('');
    const [message, setMessage] = useState('Reset your Password');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState({ verificationCode: false, password: false, confirmPassword: false });

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;


    const handleValidation = () => {
        if (!verificationCode) {
            setError(prevState => ({ ...prevState, verificationCode: true }));
            setErrorMessage("Verification Code is Required")
            return false;
        }
        if (!password) {
            setError(prevState => ({ ...prevState, password: true }));
            setErrorMessage("Password is Required")
            return false;
        }
        if (!confirmPassword) {
            setError(prevState => ({ ...prevState, confirmPassword: true }));
            setErrorMessage("Confirm Password is Required")
            return false;
        }
        if (!passwordRegex.test(password)) {
            setError(prevState => ({ ...prevState, password: true }));
            setErrorMessage("Password must be 8 characters or more, with at least one uppercase letter, one lowercase letter, one digit, and one special character.")
            return false;
        }

        if (password !== confirmPassword) {
            setError(prevState => ({ ...prevState, confirmPassword: true }));
            setErrorMessage("Confirm Password and Password should be same.")
            return false;
        }
        setError(false);
        return true;
    }

    async function onSubmit(e) {
        e.preventDefault();
        const isValid = handleValidation();
        if (!isValid) { return; }
        setError({ verificationCode: false, password: false, confirmPassword: false });
        let pw_hash = sha256(`${password} ${userId}`);
        let passwordObj = {
            id: userId,
            password: pw_hash,
        };
        setLoader(true)
        const resData = await request('post', 'getLastPasswords', passwordObj)
        if ([true, 'true'].includes(resData.res)) {
            toast.error("Password already exits.");
            setLoader(false);
        } else {
            Auth.forgotPasswordSubmit(userName, verificationCode, password).then(async () => {
                setMessage("Password Reset Successfully.")
                let pw_obj = { userid: userId, password: pw_hash };
                let userObj = { username: userName };
                await request('post', 'savepassword', pw_obj);
                const resData = await request('post', 'UpdateResetPWDate', userObj);
                if (resData) {
                    setLoader(false)
                    setVerificationCode('');
                    setPassword('')
                    setConfirmpassword('');
                    navigate('/')
                }
            }).catch((err) => {
                toast.error(err.message);
                setLoader(false)
                return;
            })
        }
    }

    const handleForgetPassword = (userName) => {
        Auth.forgotPassword(userName)
            .then(async (data) => {
                if (data.CodeDeliveryDetails.DeliveryMedium === 'SMS') {
                    setMessage(`A verification code is sent to your phone number ${data.CodeDeliveryDetails.Destination}`)
                } else {
                    setMessage(`We sent you a new temporary password. Please check your email to log back in.`)
                }
                let userObj = { username: userName };
                const resData = await request('post', 'getresetpwdatefromuser', userObj)
                if (resData.message) {
                    toast.error(resData.message);
                    return;
                }
                setUserId(resData.id)
            }).catch((err) => {
                toast.error(err.message);
                return;
            })
    }

    useEffect(() => {
        const userName = location?.state
        setUserName(userName)
        ConfigureAmplify()
        handleForgetPassword(userName);
    }, [])

    return (
        <div className='authincation h-100 p-meddle'>
            <div className='container h-100'>
                <div className='row justify-content-center h-100 align-items-center'>
                    <div className='col-md-6'>
                        <div className='authincation-content'>
                            <div className='row no-gutters'>
                                <div className='col-xl-12'>
                                    <div className='auth-form'>
                                        <div className='d-flex justify-content-center'>
                                            <img style={{ height: "60px" }} src={logo} alt="logo" />
                                        </div>
                                        <h4 className='text-center mb-4 '>{message}</h4>
                                        <form onSubmit={onSubmit}>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Verification Code</strong>
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    autoComplete='off'
                                                    value={verificationCode}
                                                    onChange={(e) => setVerificationCode(e.target.value)}
                                                />
                                                {error.verificationCode && <div className="text-danger fs-12">{errorMessage}</div>}
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>New Password</strong>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    autoComplete='off'
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                {error.password && <div className="text-danger fs-12">{errorMessage}</div>}
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Confirm New Password</strong>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    autoComplete='off'
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmpassword(e.target.value)}
                                                />
                                                {error.confirmPassword && <div className="text-danger fs-12">{errorMessage}</div>}
                                            </div>
                                            <div className='text-center mt-4'>
                                                <button disabled={loader} type="submit" className="btn btn-primary btn-block mb-2">
                                                    {loader ? <BtnLoader /> : "Submit"}
                                                </button>
                                                <button disabled={loader} onClick={() => navigate('/')} className="btn btn-primary btn-block mb-2">
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
