import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../../redux/userSlice';
import { setAuth } from '../../../redux/authSlice';

function LogoutPage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onLogout() {
    for (let key of Object.keys(localStorage)) {
      localStorage.removeItem(key);
    }
    for (let key of Object.keys(sessionStorage)) {
      sessionStorage.removeItem(key);
    }
    dispatch(setUser({ user: null }));
    dispatch(setAuth({ isAuth: false }));
    const wasRedirected = localStorage.getItem('wasRedirected');
    if(![undefined, undefined, null, null, ""].includes(wasRedirected)) {
      navigate(process.env.REACT_APP_RETAIL_URL);
    } else {
      navigate('/');
    }
  }
  return (
    <>
      <button className="dropdown-item ai-icon" onClick={onLogout}>
        <svg
          id="icon-logout" xmlns="http://www.w3.org/2000/svg"
          className="text-danger" width={18} height={18} viewBox="0 0 24 24"
          fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1={21} y1={12} x2={9} y2={12} />
        </svg>
        <span className="ms-2" >Logout </span>
      </button>
    </>
  )
}

export default LogoutPage;