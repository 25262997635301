import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import BOX_STATUS from "../../../../constant/BoxStatus";
import request from "../../../../services/AxiosInstance";
import CurdTypes from "../../../../constant/CurdTypes";
import EventTypes from "../../../../constant/EventTypes";
import RetailLabelTemplate from "../PrintTemplate";
import { useReactToPrint } from "react-to-print";
import { Loader } from "../../../components/bootstrap/Loader";

const PlaceOrder = ({
  selectedBin,
  parentaccountId,
  handleEventLog,
  getBoxes,
  pharmacyDetails,
  boxDetails,
  accountDetails,
  setGoSteps,
  setShowWizard,
  handleInvoiceCreate
}) => {
  const [loader, setLoader] = useState("");
  const [orders, setOrders] = useState([]);
  const [errors, setErrors] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [printModal, setPrintModal] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    console.log(JSON.stringify(selectedBin, null, 2));
    setOrders([
      {
        invoice_number: "",
        item: "",
        sunglasses: false,
        eyeglasses: false,
        contacts: false,
        special_order: false,
      },
    ]);
  }, [selectedBin]);

  const handleRemoveOrder = (index) => {
    setOrders((prevOrder) => {
      const updatedOrder = [...prevOrder];
      updatedOrder.splice(index, 1);
      return updatedOrder;
    });
  };

  const handleOrderChange = (index, event) => {
    setOrders((prevOrder) => {
      const updatedOrder = [...prevOrder];
      const targetName = event.target.name;

      if (targetName === "invoice_number") {
        updatedOrder[index].invoice_number = event.target.value;
      } else {
        updatedOrder[index].item = "";
        updatedOrder[index].sunglasses = false;
        updatedOrder[index].eyeglasses = false;
        updatedOrder[index].contacts = false;
        updatedOrder[index].special_order = false;

        if (!updatedOrder[index][targetName.toLowerCase()]) {
          updatedOrder[index].item = targetName;
          updatedOrder[index][targetName.toLowerCase()] = event.target.checked;
        }
      }
      return updatedOrder;
    });
  };

  const handleAddOrders = () => {
    setOrders((prevOrder) => [
      ...prevOrder,
      {
        invoice_number: "",
        item: "",
        sunglasses: false,
        eyeglasses: false,
        contacts: false,
        special_order: false,
      },
    ]);
  };

  const handleValidation = () => {
    const newErrors = {};
    for (let entry of orders) {
      if (entry.invoice_number === "") {
        toast.error("Invoice number is missing ");
        newErrors.item = "error";
        break;
      }
      if (entry.item === "") {
        toast.error("Item is not selected");
        newErrors.invoice_number = "error";
        break;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (type) => {
    if (handleValidation()) {
      let _items = [];
      orders &&
        orders.forEach((value) => {
          let data = {};
          if (value.item !== "") {
            data["invoice_number"] = value.invoice_number;
            data["item"] = value.item;
            _items.push(data);
          }
        });
      setOrderDetails(_items);

      let orderdata = {};
      orderdata["profile_id"] = Array.isArray(selectedBin)
        ? selectedBin[0]?.profile_id
        : selectedBin?.profile_id;
      orderdata["mobile_no"] = Array.isArray(selectedBin)
        ? selectedBin[0]?.mobile_no
        : selectedBin?.mobile_no;
      orderdata["bin_id"] = Array.isArray(selectedBin)
        ? selectedBin[0]?.bin_id
        : selectedBin?.bin_id;
      orderdata["pickup_code"] = Array.isArray(selectedBin)
        ? selectedBin[0]?.pickup_code
        : selectedBin?.pickup_code;
      orderdata["stock_code"] = Array.isArray(selectedBin)
        ? selectedBin[0]?.stock_code
        : selectedBin?.stock_code;
      orderdata["box_id"] = Array.isArray(selectedBin)
        ? selectedBin[0]?.box_id
        : selectedBin?.box_id;
      orderdata["type_id"] = Array.isArray(selectedBin)
        ? selectedBin[0]?.type_id
        : selectedBin?.type_id;
      orderdata["items"] = _items;
      console.log(JSON.stringify(orderdata, null, 2));
      setLoader(true);
      if (orderdata !== null) {
        const resOrder = await request(
          "post",
          "apis/i-ecom/handleorders",
          orderdata
        );
        if (resOrder.message) {
          setLoader(false);
          toast.error("Something went wrong.");
          return;
        }
        toast.success("Order is created Successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        const orderIds = resOrder && resOrder.map((item) => item.id);
        const user = JSON.parse(sessionStorage.getItem("user"));
        const user_id = user.username;
        let descriptions = `Order is assigned with stock code ${selectedBin[0]?.stock_code} and pick up ${selectedBin[0]?.pickup_code}`;
        const event_params = {
          event_name: CurdTypes.UPDATE,
          event_type_name: EventTypes.ASSIGNED,
          description: descriptions,
          type_id: BOX_STATUS.ASSIGNED,
          parent_account_id: parentaccountId,
          user_id: user_id,
          order_id: orderIds,
          box_id: selectedBin[0]?.box_id,
        };
        if (type === 0) {
          setPrintModal(true);
          setTimeout(() => {
            handleInvoiceCreate()
            handlePrint();
            setPrintModal(false);
            clearWizard();
          }, 1500);
        } else {
          setPrintModal(false);
        }
        handleEventLog(event_params);
        getBoxes();
      }
      setLoader(false);
    }
  };

  const clearWizard = () => {
    setOrders([]);
    setShowWizard(false);
  };

  return (
    <div className="col-12 orderScroll">
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        ""
      )}
      {orders &&
        orders.map((element, index) => (
          <div className="row" key={index}>
            <div className="col-6 d-flex">
              <div className="mt-4">
                {index > 0 ? (
                  <Button
                    className="me-2 fs-26"
                    variant="outlined"
                    onClick={() => handleRemoveOrder(index)}
                  >
                    <i class="fa-solid fa-circle-minus" />
                  </Button>
                ) : (
                  <Button
                    className="me-2 fs-24"
                    variant="outlined"
                    onClick={() => handleAddOrders()}
                  >
                    <i class="fa-solid fa-circle-plus" />
                  </Button>
                )}
              </div>
              <div>
                <label className="pt-3">Invoice</label>
                <input
                  type="text"
                  name="invoice_number"
                  value={element.invoice_number}
                  onChange={(e) => {
                    handleOrderChange(index, e);
                  }}
                  className="form-control form-control-sm fs-16"
                />
                {errors.inc && (
                  <div className="text-danger fs-12">{errors.profileId}</div>
                )}
              </div>
            </div>
            <div className="col-6 mt-3">
              <h5>Items</h5>
              <div className="form-check custom-checkbox mb-3 ml-2">
                <input
                  type="checkbox"
                  name="Sunglasses"
                  checked={element.sunglasses}
                  onChange={(e) => handleOrderChange(index, e)}
                  className="form-check-input"
                />
                <label className="form-check-label">Sunglasses</label>
              </div>
              <div className="form-check custom-checkbox mb-3 ml-2">
                <input
                  type="checkbox"
                  name="Eyeglasses"
                  checked={element.eyeglasses}
                  onChange={(e) => handleOrderChange(index, e)}
                  className="form-check-input"
                />
                <label className="form-check-label">Eyeglasses</label>
              </div>
              <div className="form-check custom-checkbox mb-3 ml-2">
                <input
                  type="checkbox"
                  name="Contacts"
                  checked={element.contacts}
                  onChange={(e) => handleOrderChange(index, e)}
                  className="form-check-input"
                />
                <label className="form-check-label">Contacts</label>
              </div>
              <div className="form-check custom-checkbox mb-3 ml-2">
                <input
                  type="checkbox"
                  name="Special_Order"
                  checked={element.special_order}
                  onChange={(e) => handleOrderChange(index, e)}
                  className="form-check-input"
                />
                <label className="form-check-label">Special Order / OTC</label>
              </div>
            </div>
          </div>
        ))}
      <div className="row">
        <div
          className="col-xl-12 col-xxl-12 mt-4"
          style={{ textAlign: "right" }}
        >
          <Button
            className="m-2 btn-sm btn-secondary sw-btn-prev me-1"
            onClick={() => setGoSteps(1)}
          >
            Prev
          </Button>
          <Button
            variant="primary"
            className="m-2 btn-sm"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="m-2 btn-sm"
            onClick={() => handleSubmit(0)}
          >
            Save & Print
          </Button>
        </div>
      </div>
      <Modal className="fade" show={printModal} centered>
        <Modal.Body>
          <RetailLabelTemplate
            boxId={
              Array.isArray(selectedBin)
                ? selectedBin[0]?.box_id
                : selectedBin?.box_id
            }
            ref={componentRef}
            accountDetails={accountDetails}
            pharmacyDetails={pharmacyDetails}
            boxDetails={boxDetails}
            connectionType={0}
            ProfileNumber={
              Array.isArray(selectedBin)
                ? selectedBin[0]?.profile_id
                : selectedBin?.profile_id
            }
            orderDetails={orderDetails}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PlaceOrder;
