import React, { useEffect, useState, useMemo, useRef } from 'react';
import request from '../../../services/AxiosInstance';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment-timezone';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import 'ag-grid-enterprise';
import { GrPowerReset } from "react-icons/gr";
import { Button, Modal, Nav, Tab } from 'react-bootstrap';
import errorData from "../../../common/twilio-error-codes.json"
import { maskPhoneNumber } from '../../../helper/helper';
import { toast } from 'react-toastify';
import { Loader } from '../../components/bootstrap/Loader';

ModuleRegistry.registerModules([ServerSideRowModelModule]);
let TransactionFilterObj = {
    box_ids: [],
    fromdate: "",
    searchObject: "",
    pageNo: 0,
    pageSize: 20,
    sortOrder: "desc",
    sortColumn: "created_on",
    store_ids: [],
    tableName: "",
    todate: "",
    group_ids: [],
    group_id: null
};

const TransactionSortObj = {
    carrier: { tableName: 'system_log', sortColumn: 'carrier' },
    phone: { tableName: 'system_log', sortColumn: 'phone' },
    store: { tableName: 'accounts', sortColumn: 'name' },
    box: { tableName: 'boxes', sortColumn: 'name' },
    created_on: { tableName: 'system_log', sortColumn: 'created_on' },
    status: { tableName: 'system_log', sortColumn: 'status' },
    group: { tableName: 'groups', sortColumn: 'name' },
};

const MessageLog = () => {
    const gridRef = useRef();
    const groupRef = useRef([]);
    const [storeData, setStoreData] = useState([]);
    const [location, setLocation] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState('');
    const [rowPerPage] = useState(5);
    const [loader, setLoader] = useState(false)
    const [group, setGroup] = useState([]);

    TransactionFilterObj.group_id = sessionStorage.getItem("groupID")
    const getAccount = async () => {
        const account = await request('get', 'accounts');
        const storeId = account.map(item => item.id);
        const obj = { store_ids: storeId };
        const storeData = account.map(item => ({ id: item.id, name: item.name }));
        setStoreData(storeData);
        const getBoxes = await request('post', 'getBoxesByStoreIds', obj);
        const location = getBoxes.map(item => ({ id: item.id, name: item.name }));
        const groupResult = await request('get', `groups`);
        groupRef.current = groupResult.data;
        setGroup(groupResult.data)
    };

    const updateLog = async () => {
        const logUpdateRes = await request('get', 'apis/i-ecom/handleorders/updateMessageLog')
        if (logUpdateRes.message) {
            console.log(`Error in get Message status update ${logUpdateRes.message}`)
            return
        }
        return logUpdateRes
    }

    const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

    const onPaginationChanged = (params) => {
        const newPageSize = params.api.paginationGetPageSize();
        if (rowPerPage !== newPageSize) {
            TransactionFilterObj.pageSize = newPageSize;
        }
    };
    const gridOptions = {
        rowModelType: 'serverSide',
        gridBodyCls: 'ag-layout-auto-height',
    };

    const onGridReady = async (params) => {
        await updateLog();
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
        params.api.sizeColumnsToFit();
    };

    const getServerSideDatasource = () => {
        return {
            getRows(params) {
                TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
                const filterObject = params.request && params.request.filterModel
                const filterColumns = Object.keys(filterObject)

                // to set box_ids
                if (filterObject.box) {
                    const filterValues = filterObject.box.values;
                    if (filterValues.length > 0) {
                        TransactionFilterObj.box_ids = location.filter(boxes => {
                            return filterValues.includes(boxes.name)
                        }).map(box => box.id)
                    }
                } else {
                    TransactionFilterObj.box_ids = []
                }

                // to set store_ids
                if (filterObject.store) {
                    const filterValues = filterObject.store.values;
                    TransactionFilterObj.store_ids = storeData.filter(accounts => {
                        return filterValues.includes(accounts.name)
                    }).map(store => store.id)
                } else {
                    TransactionFilterObj.store_ids = []
                }

                //to set group id
                if (filterObject.group) {
                    const filterValues = filterObject.group.values;
                    TransactionFilterObj.group_ids = groupRef.current.filter(val => {
                        return filterValues.includes(val.name)
                    }).map(val => val.id)
                } else {
                    TransactionFilterObj.group_ids = []
                }

                // to set fromdate and todate
                if (filterObject.created_on) {
                    const dateValue = filterObject.created_on;
                    if (dateValue.operator) {
                        // for date range
                        if (dateValue.condition1.type === 'fromdate' && dateValue.condition2.type === 'todate') {
                            const date1 = moment(dateValue.condition1.dateFrom).format('YYYY-MM-DD')
                            const date2 = moment(dateValue.condition2.dateFrom).format('YYYY-MM-DD')
                            TransactionFilterObj.fromdate = date1
                            TransactionFilterObj.todate = date2
                        }
                        if (dateValue.condition2.type === 'fromdate' && dateValue.condition1.type === 'todate') {
                            const date2 = moment(dateValue.condition1.dateFrom).format('YYYY-MM-DD')
                            const date1 = moment(dateValue.condition2.dateFrom).format('YYYY-MM-DD')
                            TransactionFilterObj.fromdate = date1
                            TransactionFilterObj.todate = date2
                        }
                    } else {
                        // for from/to date
                        const date = moment(dateValue.dateFrom).format('YYYY-MM-DD')
                        if (dateValue.type === 'fromdate') {
                            TransactionFilterObj.fromdate = date
                            TransactionFilterObj.todate = ''
                        } else {
                            TransactionFilterObj.todate = date
                            TransactionFilterObj.fromdate = ''
                        }
                    }
                } else {
                    TransactionFilterObj.todate = '';
                    TransactionFilterObj.fromdate = ''
                }

                if (filterObject) {
                    if (filterColumns.length === 0) {
                        TransactionFilterObj.searchObject = null
                    } else {
                        TransactionFilterObj.searchObject = filterObject
                    }
                }

                if (filterObject) {
                    if (filterColumns.length === 0) {
                        TransactionFilterObj.searchObject = ''
                    } else {
                        TransactionFilterObj.searchObject = filterObject
                    }
                }

                if (params.request.sortModel.length > 0) {
                    TransactionFilterObj.tableName = TransactionSortObj[params.request.sortModel[0].colId].tableName
                    TransactionFilterObj.sortColumn = TransactionSortObj[params.request.sortModel[0].colId].sortColumn
                    TransactionFilterObj.sortOrder = params.request.sortModel[0].sort
                }
                const isStoreNotSelected = Boolean(filterObject.store && filterObject.store.values.length === 0)
                const isBoxNotSelected = Boolean(filterObject.box && filterObject.box.values.length === 0)
                const isGroupNotSelected = Boolean(filterObject.box && filterObject.group.values.length === 0)

                if (isStoreNotSelected === true || isBoxNotSelected === true || isGroupNotSelected === true) {
                    // store or box is not selected, so empty data
                    params.success({ rowData: [], rowCount: 0 });

                } else { // store or box is selected
                    apiCall()
                }
                function apiCall() {
                    request('post', 'apis/i-ecom/handleorders/order/getMessageLog', TransactionFilterObj)
                        .then(response => {
                            if (response && response.results) {
                                if (response.results.length > 0) {
                                    params.success({ rowData: response.results, rowCount: response.total });
                                } else {
                                    params.success({ rowData: [], rowCount: 0 });
                                }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            params.fail();
                        })
                }
            }
        };
    }

    const DateCellFormatter = (params) => {
        if (params && params.data && params.data.created_on) {
            const strTimezone = sessionStorage.getItem('timeZone')
            const sessionTimeZone = strTimezone.split(') ')[1];
            const timeZone = ![undefined, 'undefined', null, 'null', ''] ? sessionTimeZone : 'America/Los_Angeles';
            const date = moment(new Date(params.data.created_on));
            return date.tz(timeZone).format('MM/DD/YYYY hh:mm:ss A');
        } else {
            return ''
        }
    }

    const ActionCellRenderer = ({ data }) => {
        const response = JSON.parse(data.response)
        const infoMessage = ![undefined, 'undefined', null, 'null', ''].includes(response.code) ? errorData.find((errorObj) => errorObj.code === response.code) : { description: data.status };
        const tabData = {
            content: JSON.parse(data.request_body),
            status: [null, 'null'].includes(infoMessage.description) ? infoMessage.message : infoMessage.description,
            media: data.picture_url
        }
        return (
            <>
                <Button variant="link" className='pr-0' onClick={() => {
                    setModal(true);
                    setModalData(tabData)
                }}>
                    <div
                        title={infoMessage === '' ? '' : [null, 'null'].includes(infoMessage.description) ? infoMessage.message : infoMessage.description}
                        data-toggle="tooltip"
                        className="tooltip-test"
                    >
                        <i class="fa-solid fa-eye" />
                    </div>
                </Button>
            </>
        );
    }


    const StatusRender = ({ data }) => {
        if ([undefined, 'undefined', null, 'null', '', 400, '400'].includes(data.status)) {
            return "Failed"
        } else {
            return data.status.length > 0 && data.status.charAt(0).toUpperCase() + data.status.slice(1)
        }
    }

    const TableHeader = [
        {
            headerName: "Date",
            field: "created_on",
            cellRenderer: DateCellFormatter,
            filter: 'agDateColumnFilter',
            width: 200,
            filterParams: {
                filterOptions: [
                    {
                        displayKey: 'fromdate',
                        displayName: "FromDate",
                        predicate: (_, cellValue) => cellValue
                    },
                    {
                        displayKey: 'todate',
                        displayName: "ToDate",
                        predicate: (_, cellValue) => cellValue
                    },
                ],
                maxNumConditions: 2,
            },

        },
        {
            headerName: "Destination",
            field: "phone",
            valueFormatter: params => maskPhoneNumber(params.data.phone.length > 0 ? params.data.phone.slice(-10) : params.data.phone) || params.data.mobile_no,
        },
        { headerName: 'Type', field: "carrier" },
        {
            headerName: "Account", field: "store", filter: true,
            filterParams: {
                values: storeData.map(account => {
                    return account.name
                })
            },
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
        {
            headerName: "Location", field: "box", filter: true,
            filterParams: {
                values: location.map(box => {
                    return box.name
                })
            },
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
        { headerName: 'Content', filter: false, sortable: false, cellRenderer: ActionCellRenderer },
        { headerName: 'Status', field: "status", cellRenderer: StatusRender },
        {
            headerName: "Group", field: "group", filter: true,
            filterParams: {
                values: group.map(g => {
                    return g.name
                })
            }
        }
    ];

    const handleFilterReset = async () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setFilterModel(null);
            gridRef.current.api.onFilterChanged();
        }
    }

    const fetchData = async () => {
        const totalRows = gridRef.current.api.paginationGetRowCount();
        TransactionFilterObj.pageSize = totalRows
        const response = await request('post', 'apis/i-ecom/handleorders/order/getMessageLog', TransactionFilterObj);
        if (!response || !response.results) {
            toast.error('Error while fetching data for CSV');
            setLoader(false)
            return []
        }
        return response.results;
    };

    const onExportAllData = async () => {
        setLoader(true)
        const allData = await fetchData();

        if (allData.length > 0) {
            const columnDefs = gridRef.current.api.getColumnDefs();

            const csvData = allData.map(row => {
                let rowData = {};
                columnDefs.forEach(colDef => {
                    const field = colDef.field;
                    if (field) {
                        rowData[field] = row[field] ?? '';
                    }
                });
                return rowData;
            });

            let csvContent = 'data:text/csv;charset=utf-8,';
            const headers = columnDefs.map(colDef => colDef.headerName).join(',');
            csvContent += `${headers}\n`;

            csvData.forEach(row => {
                const rowString = columnDefs.map(colDef => {
                    const field = colDef.field;
                    if (field) {
                        return `"${row[field]}"`;
                    }
                    return '';
                }).join(',');
                csvContent += `${rowString}\n`;
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'MessageLog_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoader(false)
        } else {
            setLoader(false)
            toast.error("Error while creating CSV")
        }
    };

    const getContextMenuItems = () => {
        return [
            'copy',
            'copyWithHeaders',
            {
                name: 'Export All Data',
                action: onExportAllData,
            },
        ];
    };

    useEffect(() => {
        getAccount();
    }, [])

    return (
        <div>
            {loader ? <div className="loader">
                <Loader />
            </div> : ""}
            <div className='text-end fs-26 mb-3'>
                <span className='mx-4' onClick={() => handleFilterReset()}>
                    <GrPowerReset size={26} color='gray' />
                </span>
            </div>
            <div id="myGrid" className={"ag-theme-quartz"} style={{ boxSizing: "border-box", width: "100%" }}>
                <AgGridReact
                    ref={gridRef}
                    sideBar={false}
                    suppressMenuHide={true}
                    columnDefs={TableHeader}
                    defaultColDef={{
                        minWidth: 100,
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            filterOptions: [{
                                displayKey: 'searchtext',
                                displayName: 'Search Text',
                                predicate: (cellValue) => cellValue
                            }],
                            maxNumConditions: 1,
                        },
                        resizable: true,
                        sortable: true,
                        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                    }}
                    pagination={true}
                    paginationPageSize={20}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    cacheBlockSize={20}
                    maxBlocksInCache={0}
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    onPaginationChanged={onPaginationChanged}
                    getContextMenuItems={getContextMenuItems}
                />
                <Modal
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                    className="fade"
                    show={modal}
                    centered
                >
                    <Modal.Body>
                        <Tab.Container defaultActiveKey='Content'>
                            <Nav as='ul' className='nav-tabs'>
                                <Nav.Item as='li' >
                                    <Nav.Link eventKey={'Content'}>
                                        Content
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li' >
                                    <Nav.Link eventKey={'Status'}>
                                        Status
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li' >
                                    <Nav.Link eventKey={'Media'}>
                                        Media
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className='pt-4'>
                                <Tab.Pane eventKey='Content'>
                                    <p>
                                        {modalData && modalData.content}
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey='Status'>
                                    <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                        {modalData && modalData.status}
                                    </pre>
                                </Tab.Pane>
                                <Tab.Pane eventKey='Media'>
                                    {[undefined, "undefined", null, 'null', ''].includes(modalData.media) ? "" :
                                        <img src={modalData.media} alt="media" />}
                                </Tab.Pane>
                            </Tab.Content>
                            <div className='row pt-5'>
                                <div className='col-12 text-end'>
                                    <Button
                                        onClick={() => setModal(false)}
                                        className='me-2 btn-sm' variant='primary'>
                                        Ok
                                    </Button>
                                </div>
                            </div>
                        </Tab.Container>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default MessageLog;
