import React, { useEffect, useReducer, useState } from "react";
import { Collapse } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { MenuList } from './Menu';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const [dashboard, setDashboard] = useState(false);;
  const [assigned, setAssigned] = useState(false);
  const [stocked, setStocked] = useState(false);
  const [pickedUp, setPickedUp] = useState(false);
  const [returnStock, setReturnStock] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [audit, setAudit] = useState(false);
  // const [isLocalUser,setIsLocalUser] = useState(false);

  const handleMenuActive = status => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }

  const handlePermissions = () => {
    let permissions = JSON.parse(sessionStorage.getItem("permissions"));
    if (permissions && permissions.length > 0) {
      let permissionObj = {};
      permissions.forEach((item) => {
        switch (item.name.trim()) {
          case "Weekly Total":
            permissionObj.weeklyTotal = item.is_allowed === 0 ? false : true;
            break;
          case "Sales":
            permissionObj.sales = item.is_allowed === 0 ? false : true;
            break;
          case "Accounts":
            permissionObj.accounts = item.is_allowed === 0 ? false : true;
            break;
          case "Daily Sales States":
            permissionObj.dailySalesStates = item.is_allowed === 0 ? false : true;
            break;
          case "View":
            permissionObj.view = item.is_allowed === 0 ? false : true;
            break;
          case "Assign":
            permissionObj.assign = item.is_allowed === 0 ? false : true;
            break;
          case "UnAssign":
            permissionObj.unAssign = item.is_allowed === 0 ? false : true;
            break;
          case "Assigned Items":
            permissionObj.assigned = item.is_allowed === 0 ? false : true;
            setAssigned(permissionObj.assigned)
            break;
          case "Stocked Items":
            permissionObj.stocked = item.is_allowed === 0 ? false : true;
            setStocked(permissionObj.stocked)
            break;
          case "Picked Up Items":
            permissionObj.pickedUpItems = item.is_allowed === 0 ? false : true;
            setPickedUp(permissionObj.pickedUpItems)
            break;
          case "Transactions":
            permissionObj.transactions = item.is_allowed === 0 ? false : true;
            setTransaction(permissionObj.transactions)
            break;
          case "Return to stock":
            permissionObj.transactions = item.is_allowed === 0 ? false : true;
            setReturnStock(permissionObj.transactions)
            break;
          case "Audit trail":
            permissionObj.transactions = item.is_allowed === 0 ? false : true;
            setAudit(permissionObj.transactions)
            break;
          default:
            break;
        }
      });
      if (
        permissionObj.weeklyTotal &&
        permissionObj.sales &&
        permissionObj.accounts &&
        permissionObj.dailySalesStates
      ) {
        setDashboard(true);
      } else {
        setDashboard(false);
      }
    } else {
      setDashboard(false);
    }
  }

  useEffect(() => {
    handlePermissions();
    // const userEmail = JSON.parse(sessionStorage.getItem('user')).attributes.email;
    // if(userEmail.includes('ilocalbox')){
    //   setIsLocalUser(true)
    // }else{
    //   setIsLocalUser(false)
    // }
  },[])

  return (
    <div className="deznav">
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            if (data.title === 'Dashboard' && !dashboard) {
              return null;
            }
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index} >{data.title}</li>
              )
            } else {
              return (
                <li className={`has-menu ${state.active === data.title ? 'mm-active' : ''}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ?
                    <Link to={"#"}
                      className="has-arrow ai-icon"
                      onClick={() => { handleMenuActive(data.title) }}
                    >
                      {data.iconStyle}{" "}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                    :
                    <Link to={data.to} >
                      {data.iconStyle}{" "}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  }
                  <Collapse in={state.active === data.title ? true : false}>
                    <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                      {data.content && data.content.map((data, index) => {
                        if (data.title === 'Assigned Items' && !assigned) {
                          return null;
                        }
                        if (data.title === 'Stocked Items' && !stocked) {
                          return null;
                        }
                        if (data.title === 'Picked Up Items' && !pickedUp) {
                          return null;
                        }
                        if (data.title === 'Return to stock' && !returnStock) {
                          return null;
                        }
                        if (data.title === 'Transactions' && !transaction) {
                          return null;
                        }
                        if (data.title === 'Audit Trail' && !audit) {
                          return null;
                        }
                        // if (data.title === 'Message Log') {
                        //   return null;
                        // }
                        return (
                          <li key={index}
                            className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                          >
                            <Link to={data.to}>
                              {data.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Collapse>
                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  );

}

export default SideBar;
