/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import request from "./../../../services/AxiosInstance";
import { Button, Modal, Overlay } from "react-bootstrap";
import { Loader } from "../../components/bootstrap/Loader";
import { useNavigate } from "react-router-dom";
import ManageRole from "./manageRole";
import { AgGridReact } from "ag-grid-react";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import "ag-grid-enterprise";

ModuleRegistry.registerModules([ServerSideRowModelModule]);
let TransactionFilterObj = {
  searchtext: "",
  pageNo: 0,
  pageSize: 20,
  sortOrder: "desc",
  sortColumn: "created_on",
  tableName: "",
};

const TransactionSortObj = {
  name: { tableName: "groups", sortColumn: "name" },
  username: { tableName: "groups", sortColumn: "parent_account_id" },
};

const RolesList = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState([]);
  const [roleRowData, setRoleRowData] = useState("");
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [rowPerPage] = useState(5);

  const handleRoleDelete = async () => {
    const rowIdToDelete = roleRowData && roleRowData[0].id;
    setLoader(true);
    const result = fetch(
      `${process.env.REACT_APP_BASEURL}role-management?id=${rowIdToDelete}`,
      {
        method: "DELETE",
        withCredentials: true,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
              .jwtToken,
          "Content-Type": "application/json",
        },
      }
    );
    result
      .then((result) => {
        result.json().then((res) => {
          setLoader(false);
          if ([200, "200"].includes(res?.status)) {
            const newData = roleData.filter((x) => x.id !== rowIdToDelete);
            setRoleData(newData);
            setDeleteModal(false);
            setLoader(false);
            toast.success("Role deleted successfully");
          } else {
            toast.error(res.message);
            return;
          }
        });
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.message);
      });
  };

  const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

  const onPaginationChanged = (params) => {
    const newPageSize = params.api.paginationGetPageSize();
    if (rowPerPage !== newPageSize) {
      TransactionFilterObj.pageSize = newPageSize;
    }
  };
  const gridOptions = {
    rowModelType: "serverSide",
    gridBodyCls: "ag-layout-auto-height",
  };

  const onGridReady = (params) => {
    const datasource = getServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
  };

  const getServerSideDatasource = () => {
    return {
      getRows(params) {
        TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
        const filterObject = params.request && params.request.filterModel;
        const filterColumns = Object.keys(filterObject);

        if (filterObject) {
          if (filterColumns.length === 0) {
            TransactionFilterObj.searchObject = "";
          } else {
            Object.keys(filterObject).forEach((colId) => {
              TransactionFilterObj.searchObject = filterObject;
            });
          }
        }

        if (params.request.sortModel.length > 0) {
          TransactionFilterObj.tableName =
            TransactionSortObj[params.request.sortModel[0].colId].tableName;
          TransactionFilterObj.sortColumn =
            TransactionSortObj[params.request.sortModel[0].colId].sortColumn;
          TransactionFilterObj.sortOrder = params.request.sortModel[0].sort;
        }

        apiCall();

        function apiCall() {
          request("post", "role-management/list", TransactionFilterObj)
            .then((response) => {
              if (response && response.data && response.data.results) {
                if (response.data.results.length > 0) {
                  params.success({
                    rowData: response.data.results,
                    rowCount: response.data.total,
                  });
                  setRoleData(response.data.results);
                } else {
                  params.success({ rowData: [], rowCount: 0 });
                  setRoleData([]);
                }
              } else {
                params.success({ rowData: [], rowCount: 0 });
                setRoleData([]);
              }
            })
            .catch((error) => {
              console.error(error);
              params.fail();
              setRoleData([]);
            });
        }
      },
    };
  };

  const ActionCellRenderer = ({ data }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleView = () => {
      if (isView) {
        const _groupData = roleData.filter((val) => val.id === data.id);
        if (_groupData.length > 0) {
          setType("View");
          setRoleRowData(_groupData);
        } else {
          toast.error("Data Not Found!");
        }
      }
    };

    const handleEdit = () => {
      if (isEdit) {
        const _groupData = roleData.filter((val) => val.id === data.id);
        if (_groupData.length > 0) {
          setType("Edit");
          setRoleRowData(_groupData);
        } else {
          toast.error("Data Not Found!");
        }
      }
    };

    const handleDelete = () => {
      if (isDelete) {
        const _groupData = roleData.filter((val) => val.id === data.id);
        if (_groupData.length > 0) {
          setRoleRowData(_groupData);
          setDeleteModal(true);
        } else {
          toast.error("Data Not Found!");
        }
      }
    };

    return (
      <>
        <Button
          variant="link"
          className="pr-0"
          ref={target}
          onClick={() => setShow(!show)}
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </Button>
        <Overlay target={target.current} show={show} placement="right">
          {({
            placement: _placement,
            arrowProps: _arrowProps,
            show: _show,
            popper: _popper,
            hasDoneInitialMeasure: _hasDoneInitialMeasure,
            ...props
          }) => (
            <div className="action-btn" {...props}>
              <span
                className={`action-link ${isView ? "" : "cursor-na"}`}
                onClick={() => handleView()}
              >
                View
              </span>
              <span
                className={`action-link ${isEdit ? "" : "cursor-na"}`}
                onClick={() => handleEdit()}
              >
                Edit
              </span>
              <span
                className={`action-link ${isDelete ? "" : "cursor-na"}`}
                onClick={() => handleDelete()}
              >
                Delete
              </span>
            </div>
          )}
        </Overlay>
      </>
    );
  };

  const TableHeader = [
    { headerName: "Name", field: "name" },
    {
      headerName: "Actions",
      field: "Actions",
      filter: false,
      sortable: false,
      cellRenderer: ActionCellRenderer,
    },
  ];

  const handlePermissions = () => {
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    if (!permissions || permissions.length === 0) {
      setIsAdd(false);
      setIsView(false);
      setIsEdit(false);
      setIsDelete(false);
      return;
    }

    const manageBoxes = permissions.filter(
      (item) => item.permission_entity_type === "Manage Groups"
    );

    if (manageBoxes.length === 0) {
      setIsAdd(false);
      setIsView(false);
      setIsEdit(false);
      setIsDelete(false);
      return;
    }

    setIsAdd(
      manageBoxes.some(
        (item) => item.name.trim() === "Add" && item.is_allowed === 1
      )
    );
    setIsView(
      manageBoxes.some(
        (item) => item.name.trim() === "View" && item.is_allowed === 1
      )
    );
    setIsEdit(
      manageBoxes.some(
        (item) => item.name.trim() === "Modify" && item.is_allowed === 1
      )
    );
    setIsDelete(
      manageBoxes.some(
        (item) => item.name.trim() === "Delete" && item.is_allowed === 1
      )
    );
  };

  useEffect(() => {
    handlePermissions();
  }, []);

  if (type === "View" || type === "Edit") {
    return (
      <>
        <ManageRole rowData={roleRowData} type={type} setType={setType} />
      </>
    );
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <div className="text-end mb-3">
            <Button
              onClick={() => navigate("/role")}
              disabled={!isAdd}
              className="btn-sm btn-primary btn-rounded"
            >
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </Button>
          </div>
          <div className="row">
            <div
              id="myGrid"
              className={"ag-theme-alpine"}
              style={{ boxSizing: "border-box", width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                sideBar={false}
                suppressMenuHide={true}
                columnDefs={TableHeader}
                defaultColDef={{
                  flex: 1,
                  minWidth: 150,
                  floatingFilter: true,
                  filter: "agTextColumnFilter",
                  filterParams: {
                    filterOptions: [
                      {
                        displayKey: "searchtext",
                        displayName: "Search Text",
                        predicate: (cellValue) => cellValue,
                      },
                    ],
                    maxNumConditions: 1,
                  },
                  resizable: true,
                  sortable: true,
                  menuTabs: ["generalMenuTab", "columnsMenuTab"],
                }}
                pagination={true}
                paginationPageSize={20}
                paginationPageSizeSelector={paginationPageSizeSelector}
                cacheBlockSize={20}
                maxBlocksInCache={0}
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                onPaginationChanged={onPaginationChanged}
                frameworkComponents={{
                  actionCellRenderer: ActionCellRenderer,
                }}
              />
            </div>
            <Modal className="fade" show={deleteModal} centered>
              <Modal.Header>
                <Modal.Title>Are you sure you want to delete?</Modal.Title>
              </Modal.Header>
              <Modal.Body>{roleRowData && roleRowData[0].name}</Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-sm"
                  variant="danger light"
                  onClick={() => setDeleteModal(false)}
                >
                  Close
                </Button>
                <Button
                  className="btn-sm"
                  variant="primary"
                  onClick={() => handleRoleDelete()}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default RolesList;
