import React from 'react'
import BOX_STATUS from '../../../constant/BoxStatus';
import BOX_TYPE from './../../../constant/BoxTypes';

let nums = Array.from(Array(3).keys());
let nums1 = Array.from(Array(12).keys());
let BinIds = [];
// let deactivatedBin = []; 
let IsSaved;

const HD36Layout = ({ BinStatus, deactivatedBins, getPrescriptionDetails, Selectedbin, isViewPermissionAllowed, box_position, expansion, ExpansionsCounts, boxTypeId }) => {

    const checkIsSaved = (binId) => {
        if (BinStatus.length > 0) {
            BinStatus.map((binitem) => {
                if (binitem.bin_id === binId) {
                    IsSaved = true;
                }
                else {
                    IsSaved = false;
                }
                return true;
            })
        }
        else {
            IsSaved = false;
        }
    }

    const openbin = (binId, status, pm_id) => {
        if (status === 'Assigned' || status === 'Stocked' || status === 'Hold') {
            if (deactivatedBins.includes(binId)) {
                Selectedbin(binId, box_position);
                let items = document.getElementById(binId);
                items.style.background = 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)'; // deavtivated
                items.style.color = '#ffffff'
            }
            getPrescriptionDetails(binId, pm_id);
        }
        else {
            BinIds.map((item) => {
                checkIsSaved(item);
                if (item !== binId && IsSaved === false) {
                    var items = document.getElementById(item);
                    items.style.background = 'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
                }
                return true
            })
            BinIds = [];
            BinIds.push(binId);
            if (BinIds.length > 0) {
                Selectedbin(binId, box_position);
                var items = document.getElementById(binId,);
                items.style.background = 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)';
            }
        }
    }

    const getColumns = (currentIndex, currentColumnCount, previousColumnCount, side) => {
        let columns = [];
        if (side === 'L') {
            for (let i = (previousColumnCount + currentColumnCount); i > previousColumnCount; i--) {
                columns.push(String.fromCharCode(65 + (i - 1)) + String.fromCharCode(65 + (i - 1)))
            }
        } else {
            for (let i = previousColumnCount; i < (previousColumnCount + currentColumnCount); i++) {
                columns.push(String.fromCharCode(65 + (i - 1)))
            }
        }
        return columns;
    }

    const getCurrentAndPreviousPosition = (side) => {
        let response = {};
        response.currentCount = expansion.bin_column_count;
        if (side === 'L') {
            let _expansionsCount = ExpansionsCounts && ExpansionsCounts.leftExpansions;
            if (expansion.box_position === -1 || expansion.box_position === '-1') {
                response.previousCount = 0;
            } else {
                response.previousCount = excludeCurrentExpansion(expansion, _expansionsCount);
            }
        } else {
            let _expansionsCount = ExpansionsCounts.rightExpansions;
            if (expansion.box_position === 1 || expansion.box_position === '1') {
                if (boxTypeId === BOX_TYPE.Locker) {
                    response.previousCount = 6;
                } else {
                    response.previousCount = 4;
                }
            } else {
                if (boxTypeId === BOX_TYPE.Locker) {
                    response.previousCount = excludeCurrentExpansion(expansion, _expansionsCount) + 6;
                } else {
                    response.previousCount = excludeCurrentExpansion(expansion, _expansionsCount) + 4;
                }
            }
        }
        return response;
    }

    const excludeCurrentExpansion = (expansion, expansionArray) => {
        let currentUnitIndex = null;
        let sumOfColumns = 0;
        for (let index = 0; index < expansionArray.length; index++) {
            if (expansionArray[index].expansionId === expansion.id) {
                currentUnitIndex = index;
                break;
            }
        }
        for (let i = 0; i < currentUnitIndex; i++) {
            if (expansionArray[i - 1] !== undefined) {
                sumOfColumns = expansionArray[i].bin_column_count + expansionArray[i - 1].bin_column_count;
            } else {
                sumOfColumns = expansionArray[i].bin_column_count;
            }
        }
        return sumOfColumns;
    }

    let elem3 = [];
    let side = box_position < 0 ? 'L' : 'R'
    if (side === 'L') {
        let prevNextCount = getCurrentAndPreviousPosition(side);
        let previousExpansionCount = (prevNextCount && prevNextCount.previousCount) ? (prevNextCount.previousCount) : 0;
        elem3 = getColumns(box_position, prevNextCount && prevNextCount.currentCount, previousExpansionCount, side);
    } else {
        let prevNextCount = getCurrentAndPreviousPosition(side);
        let previousExpansionCount = (prevNextCount && prevNextCount.previousCount) ? (prevNextCount.previousCount) : 0;
        elem3 = getColumns(box_position, prevNextCount && prevNextCount.currentCount, previousExpansionCount, side);
    }
    return (
        <div className="container">
            <div className='row'>
                {nums1.map(n1 => {
                    return (
                        <div
                            key={n1}
                            className='row g-1 gx-1'
                        >
                            {nums.map(n => {
                                if (BinStatus && BinStatus.length > 0) {
                                    var BinKey = elem3[n] + (n1 + 1)
                                    for (let j = 0; j < BinStatus.length; j++) {
                                        let pm_id = BinStatus[j].pm_id;
                                        if (BinStatus[j].type_id === BOX_STATUS.STOCKED && BinStatus[j].bin_id === BinKey) {
                                            if (deactivatedBins.includes(BinKey)) {
                                                return (
                                                    <div
                                                        id={elem3[n] + ' ' + (n1 + 1)}
                                                        key={elem3[n] + ' ' + (n1 + 1)}
                                                        style={{
                                                            border: "1px solid gray",
                                                            borderRadius: "12px",
                                                            padding: '10px',
                                                            background: 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)',
                                                            pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                            cursor: isViewPermissionAllowed ? 'auto' : 'not-allowed'
                                                        }}
                                                        onClick={() => openbin(elem3[n] + '' + (n1 + 1), 'Stocked', pm_id)}
                                                    >
                                                        {elem3[n] + ' ' + (n1 + 1)}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        title="Stocked"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-dark col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem3[n] + ' ' + (n1 + 1)}
                                                            key={elem3[n] + ' ' + (n1 + 1)}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "12px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(155deg, rgb(23,166,0) 0%, rgb(196,255,189) 90%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: isViewPermissionAllowed ? 'auto' : 'not-allowed'
                                                            }}
                                                            onClick={() => openbin((elem3[n] + '' + (n1 + 1)), 'Stocked', pm_id)}
                                                        >
                                                            {elem3[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                        else if (BinStatus[j].type_id === BOX_STATUS.ASSIGNED && BinStatus[j].bin_id === BinKey) {
                                            if (deactivatedBins.includes(BinKey)) {
                                                return (
                                                    <div
                                                        title="DEACTIVATE"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-primary col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem3[n] + ' ' + (n1 + 1)}
                                                            key={elem3[n] + ' ' + (n1 + 1)}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "12px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: isViewPermissionAllowed ? 'auto' : 'not-allowed'
                                                            }}
                                                            onClick={() => openbin(elem3[n] + '' + (n1 + 1), 'Assigned', pm_id)}
                                                        >
                                                            {elem3[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        title="Assigned"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-primary col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem3[n] + ' ' + (n1 + 1)}
                                                            key={elem3[n] + ' ' + (n1 + 1)}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "12px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: isViewPermissionAllowed ? 'auto' : 'not-allowed'
                                                            }}
                                                            onClick={() => openbin(elem3[n] + '' + (n1 + 1), 'Assigned', pm_id)}
                                                        >
                                                            {elem3[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                        else if (BinStatus[j].type_id === BOX_STATUS.HOLD && BinStatus[j].bin_id === BinKey) {
                                            if (deactivatedBins.includes(BinKey)) {
                                                return (
                                                    <div
                                                        title="DEACTIVATE"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-primary col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem3[n] + ' ' + (n1 + 1)}
                                                            key={elem3[n] + ' ' + (n1 + 1)}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "12px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: isViewPermissionAllowed ? 'auto' : 'not-allowed'
                                                            }}
                                                            onClick={() => openbin(elem3[n] + '' + (n1 + 1), 'Hold', pm_id)}
                                                        >
                                                            {elem3[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        id={elem3[n] + ' ' + (n1 + 1)}
                                                        key={elem3[n] + ' ' + (n1 + 1)}
                                                        style={{
                                                            border: "1px solid gray",
                                                            borderRadius: "12px",
                                                            padding: '10px',
                                                            background: 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)',
                                                            pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                            cursor: isViewPermissionAllowed ? 'auto' : 'not-allowed'
                                                        }}
                                                        onClick={() => openbin(elem3[n] + '' + (n1 + 1), 'Hold', pm_id)}
                                                    >
                                                        {elem3[n] + ' ' + (n1 + 1)}
                                                    </div>
                                                );
                                            }
                                        }
                                    }
                                }
                                return (
                                    <div
                                        title="Available"
                                        data-toggle="tooltip"
                                        className="tooltip-test text-primary col-4 text-center fw-bolder"
                                    >
                                        <div
                                            id={elem3[n] + ' ' + (n1 + 1)}
                                            key={elem3[n] + ' ' + (n1 + 1)}
                                            style={{
                                                border: "1px solid gray",
                                                borderRadius: "12px",
                                                padding: '10px',
                                                background: 'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)',
                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                cursor: isViewPermissionAllowed ? 'auto' : 'not-allowed'
                                            }}
                                            onClick={() => openbin(elem3[n] + '' + (n1 + 1))}
                                        >
                                            {elem3[n] + ' ' + (n1 + 1)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    )

}
export default HD36Layout;
