import React from 'react'

const Loader = () => {
    return (
        <div>
            <div className='loader'>
                <svg width="135" height="78" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg">
                    <rect y="10" width="15" height="120" rx="6" fill="#5cb85c">
                        <animate attributeName="height"
                            begin="0.5s" dur="1s"
                            values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                            repeatCount="indefinite" />
                        <animate attributeName="y"
                            begin="0.5s" dur="1s"
                            values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                            repeatCount="indefinite" />
                    </rect>
                    <rect x="30" y="10" width="15" height="120" rx="6" fill="#4cae4c">
                        <animate attributeName="height"
                            begin="0.25s" dur="1s"
                            values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                            repeatCount="indefinite" />
                        <animate attributeName="y"
                            begin="0.25s" dur="1s"
                            values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                            repeatCount="indefinite" />
                    </rect>
                    <rect x="60" width="15" height="140" rx="6" fill="#5bc0de">
                        <animate attributeName="height"
                            begin="0s" dur="1s"
                            values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                            repeatCount="indefinite" />
                        <animate attributeName="y"
                            begin="0s" dur="1s"
                            values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                            repeatCount="indefinite" />
                    </rect>
                    <rect x="90" y="10" width="15" height="120" rx="6" fill="#46b8da">
                        <animate attributeName="height"
                            begin="0.25s" dur="1s"
                            values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                            repeatCount="indefinite" />
                        <animate attributeName="y"
                            begin="0.25s" dur="1s"
                            values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                            repeatCount="indefinite" />
                    </rect>
                    <rect x="120" y="10" width="15" height="120" rx="6" fill="#428bca">
                        <animate attributeName="height"
                            begin="0.5s" dur="1s"
                            values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                            repeatCount="indefinite" />
                        <animate attributeName="y"
                            begin="0.5s" dur="1s"
                            values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                            repeatCount="indefinite" />
                    </rect>
                </svg>
            </div>
        </div>
    )
}

const BtnLoader = () => {
    return (
        <div>
            <svg width="40" height="15" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                <circle cx="15" cy="15" r="15">
                    <animate attributeName="r" from="15" to="15"
                        begin="0s" dur="0.8s"
                        values="15;9;15" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="1" to="1"
                        begin="0s" dur="0.8s"
                        values="1;.5;1" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate attributeName="r" from="9" to="9"
                        begin="0s" dur="0.8s"
                        values="9;15;9" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="0.5" to="0.5"
                        begin="0s" dur="0.8s"
                        values=".5;1;.5" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="105" cy="15" r="15">
                    <animate attributeName="r" from="15" to="15"
                        begin="0s" dur="0.8s"
                        values="15;9;15" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="1" to="1"
                        begin="0s" dur="0.8s"
                        values="1;.5;1" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
            </svg>
        </div>
    )
}

export { Loader, BtnLoader }