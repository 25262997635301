import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import request from './../../../services/AxiosInstance';
import { Button, Modal, Overlay } from "react-bootstrap";
import { Loader } from "../../components/bootstrap/Loader";
import Locations from "./boxes";
import { AgGridReact } from 'ag-grid-react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import 'ag-grid-enterprise';
import { MdAddLocationAlt } from "react-icons/md";

ModuleRegistry.registerModules([ServerSideRowModelModule]);

let TransactionFilterObj = {
    searchtext: "",
    pageNo: 0,
    pageSize: 10,
    sortOrder: "desc",
    sortColumn: "created_on",
    tableName: "",
};

const TransactionSortObj = {
    name: { tableName: 'boxes', sortColumn: 'name' },
    description: { tableName: 'boxes', sortColumn: 'description' },
    'address.city': { tableName: 'addresses', sortColumn: 'city' },
    "account.name": { tableName: 'accounts', sortColumn: 'name' }
};

const BoxList = () => {
    const gridRef = useRef();
    const navigate = useNavigate();
    const [type, setType] = useState('')
    const [boxData, setBoxData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [boxRowData, setBoxRowData] = useState('')
    const [modal, setModal] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [rowPerPage] = useState(5);

    // const getBoxesData = async () => {
    //     setLoader(true);
    //     const boxData = await request('post', 'getBoxes', TransactionFilterObj)
    //     if (boxData.message) {
    //         toast(boxData.message)
    //         setLoader(false);
    //         return;
    //     }
    //     setBoxData(boxData.results)
    //     setLoader(false);
    // }

    const boxDelete = async () => {
        setLoader(true);
        const rowIdToDelete = boxRowData && boxRowData[0].id
        const deletedRes = await request('delete', `boxes/${rowIdToDelete}`, {})
        if (deletedRes.message) {
            toast.error(deletedRes.message)
            setLoader(false);
            setModal(false);
            return;
        }
        setLoader(false);
        setModal(false);
        const newData = boxData.filter((x) => x.id !== rowIdToDelete);
        setBoxData(newData)
    }


    const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

    const onPaginationChanged = (params) => {
        const newPageSize = params.api.paginationGetPageSize();
        if (rowPerPage !== newPageSize) {
            TransactionFilterObj.pageSize = newPageSize;
        }
    };
    const gridOptions = {
        rowModelType: 'serverSide',
        gridBodyCls: 'ag-layout-auto-height'
    };

    const onGridReady = (params) => {
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    };

    const getServerSideDatasource = () => {
        return {
            getRows(params) {
                TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
                const filterObject = params.request && params.request.filterModel
                const filterColumns = Object.keys(filterObject)

                if (filterObject) {
                    if (filterColumns.length === 0) {
                        TransactionFilterObj.searchObject = ''
                    } else {
                        const filters = {};
                        let apiObj = {};
                        Object.keys(filterObject).forEach(colId => {
                            const filter = filterObject[colId];
                            let adjustedColId = colId;

                            if (colId === 'type.name') {
                                adjustedColId = 'type';
                                if (filter.filterType === 'text' && filter.type === 'searchtext') {
                                    filters[adjustedColId] = filter.filter;
                                }
                                apiObj = {
                                    type: filter
                                }
                            }
                            if (colId === 'address.city') {
                                adjustedColId = 'city';
                                if (filter.filterType === 'text' && filter.type === 'searchtext') {
                                    filters[adjustedColId] = filter.filter;
                                }
                                apiObj = {
                                    city: filter
                                }
                            }
                            if (colId === 'account.name') {
                                adjustedColId = 'name';
                                if (filter.filterType === 'text' && filter.type === 'searchtext') {
                                    filters[adjustedColId] = filter.filter;
                                }
                                apiObj = {
                                    username: filter
                                }
                            }

                            if (colId === 'type.name' || colId === 'address.city' || colId === 'account.name') {
                                TransactionFilterObj.searchObject = apiObj;
                            } else {
                                TransactionFilterObj.searchObject = filterObject;
                            }
                        });

                    }
                }

                if (params.request.sortModel.length > 0) {
                    TransactionFilterObj.tableName = TransactionSortObj[params.request.sortModel[0].colId].tableName
                    TransactionFilterObj.sortColumn = TransactionSortObj[params.request.sortModel[0].colId].sortColumn
                    TransactionFilterObj.sortOrder = params.request.sortModel[0].sort
                }

                apiCall();

                function apiCall() {
                    request('post', 'apis/i-ecom/handleorders/box/getBoxes', TransactionFilterObj)
                        .then(response => {
                            if (response && response.results) {
                                if (response.results.length > 0) {
                                    params.success({ rowData: response.results, rowCount: response.total });
                                    setBoxData(response.results)
                                } else {
                                    params.success({ rowData: [], rowCount: 0 });
                                }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            params.fail();
                        })
                }
            }
        };
    }

    const ImageCellRenderer = (props) => {
        return (
            <img
                src={props.value}
                alt="profile"
                style={{ width: '50px', height: '50px' }}
            />
        );
    };

    const ActionCellRenderer = ({ data }) => {
        const [show, setShow] = useState(false);
        const target = useRef(null);

        const handleView = () => {
            if (isView) {
                const _boxData = boxData.filter((val) => val.id === data.id)
                if (_boxData.length > 0) {
                    setType('View')
                    setBoxRowData(_boxData);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        };

        const handleEdit = () => {
            if (isEdit) {
                const _boxData = boxData.filter((val) => val.id === data.id)
                if (_boxData.length > 0) {
                    setType('Edit');
                    setBoxRowData(_boxData);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        };

        const handleDelete = () => {
            if (isDelete) {
                const _boxData = boxData.filter((val) => val.id === data.id)
                if (_boxData.length > 0) {
                    setBoxRowData(_boxData);
                    setModal(true);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        }

        return (
            <>
                <Button variant="link" className='pr-0' ref={target} onClick={() => setShow(!show)}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </Button>
                <Overlay target={target.current} show={show} placement="right">
                    {({ placement: _placement,
                        arrowProps: _arrowProps,
                        show: _show,
                        popper: _popper,
                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                        ...props }) => (
                        <div className='action-btn'
                            {...props}
                        >
                            <span
                                className={`action-link ${isView ? '' : 'cursor-na'}`}
                                onClick={() => handleView()}
                            >
                                View
                            </span>
                            <span
                                className={`action-link ${isEdit ? '' : 'cursor-na'}`}
                                onClick={() => handleEdit()}
                            >
                                Edit
                            </span>
                            <span
                                className={`action-link ${isDelete ? '' : 'cursor-na'}`}
                                onClick={() => handleDelete()}
                            >
                                Delete
                            </span>
                        </div>
                    )}
                </Overlay>
            </>
        );
    }

    const TableHeader = [
        { headerName: 'Picture', field: "picture_url", sortable: false, filter: false, cellRenderer: ImageCellRenderer },
        { headerName: "Name", field: "name" },
        { headerName: "Description", field: "description" },
        { headerName: "Type", field: "type.name", filter: false, sortable: false },
        { headerName: "City", field: "address.city" },
        { headerName: "ParentAccount", field: "account.name", filter: false, sortable: false },
        { headerName: "Actions", field: "Actions", filter: false, sortable: false, cellRenderer: ActionCellRenderer },
    ];

    const handlePermissions = () => {
        const permissions = JSON.parse(sessionStorage.getItem("permissions"));

        if (!permissions || permissions.length === 0) {
            setIsAdd(false);
            setIsView(false);
            setIsEdit(false);
            setIsDelete(false);
            return;
        }

        const manageBoxes = permissions.filter(item => item.permission_entity_type === "Manage Boxes");

        if (manageBoxes.length === 0) {
            setIsAdd(false);
            setIsView(false);
            setIsEdit(false);
            setIsDelete(false);
            return;
        }

        setIsAdd(manageBoxes.some(item => item.name.trim() === 'Add' && item.is_allowed === 1));
        setIsView(manageBoxes.some(item => item.name.trim() === 'View' && item.is_allowed === 1));
        setIsEdit(manageBoxes.some(item => item.name.trim() === 'Modify' && item.is_allowed === 1));
        setIsDelete(manageBoxes.some(item => item.name.trim() === 'Delete' && item.is_allowed === 1));
    };

    useEffect(() => {
        handlePermissions()
        // getBoxesData()
    }, []);

    if (type === 'View' || type === 'Edit') {
        return (
            <>
                <Locations rowData={boxRowData} type={type} setType={setType} />
            </>
        )
    }

    return (
        <>
            {loader ? <Loader /> : <div>
                <div className="text-end mb-3">
                    <Button onClick={() => navigate('/locations')} disabled={!isAdd} className="btn-sm btn-primary btn-rounded">
                        <MdAddLocationAlt size={26} color='black' />
                    </Button>
                </div>
                <div className="row">
                    <div className="row">
                        <div id="myGrid" className={"ag-theme-alpine"} style={{ boxSizing: "border-box", width: "100%" }}>
                            <AgGridReact
                                ref={gridRef}
                                sideBar={false}
                                suppressMenuHide={true}
                                columnDefs={TableHeader}
                                defaultColDef={{
                                    flex: 1,
                                    minWidth: 150,
                                    floatingFilter: true,
                                    filter: 'agTextColumnFilter',
                                    filterParams: {
                                        filterOptions: [{
                                            displayKey: 'searchtext',
                                            displayName: 'Search Text',
                                            predicate: (cellValue) => cellValue
                                        }],
                                        maxNumConditions: 1,
                                    },
                                    resizable: true,
                                    sortable: true,
                                    menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                                }}
                                pagination={true}
                                paginationPageSize={20}
                                paginationPageSizeSelector={paginationPageSizeSelector}
                                cacheBlockSize={20}
                                maxBlocksInCache={0}
                                onGridReady={onGridReady}
                                gridOptions={gridOptions}
                                onPaginationChanged={onPaginationChanged}
                                frameworkComponents={{
                                    imageCellRenderer: ImageCellRenderer,
                                    actionCellRenderer: ActionCellRenderer,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Modal className="fade" show={modal} centered>
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to delete?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {boxRowData && boxRowData[0].name}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-sm"
                            variant="danger light"
                            onClick={() => setModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            className="btn-sm"
                            variant="primary"
                            onClick={() => boxDelete()}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            }
        </>
    );
};

export default BoxList;
