import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import logo from "../../../images/iLocalBox__Logo.png";
import { setSideMenu } from "../../../redux/menuSlice";

const NavHader = () => {
   const dispatch = useDispatch();
   const sideMenu = useSelector(state => state.menuReducer.sideMenu);
   return (
      <div className="nav-header">
         <Link className="brand-logo">
            <img className="brand-title" src={logo} alt="" />
         </Link>

         <div className="nav-control"
            onClick={() => { dispatch(setSideMenu({ sideMenu: !sideMenu })) }}
         >
            <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
