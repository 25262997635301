const weekArray = [
    { id: 0, value: 'Sunday', openTime: '', closeTime: '' },
    { id: 1, value: 'Monday', openTime: '', closeTime: '' },
    { id: 2, value: 'Tuesday', openTime: '', closeTime: '' },
    { id: 3, value: 'Wednesday', openTime: '', closeTime: '' },
    { id: 4, value: 'Thursday', openTime: '', closeTime: '' },
    { id: 5, value: 'Friday', openTime: '', closeTime: '' },
    { id: 6, value: 'Saturday', openTime: '', closeTime: '' },
]
const kioskWeekArray = [
    { id: 0, value: 'Sunday', openTime: '', closeTime: '', isClosed: false, isOpenAll: true },
    { id: 1, value: 'Monday', openTime: '', closeTime: '', isClosed: false, isOpenAll: true },
    { id: 2, value: 'Tuesday', openTime: '', closeTime: '', isClosed: false, isOpenAll: true },
    { id: 3, value: 'Wednesday', openTime: '', closeTime: '', isClosed: false, isOpenAll: true },
    { id: 4, value: 'Thursday', openTime: '', closeTime: '', isClosed: false, isOpenAll: true },
    { id: 5, value: 'Friday', openTime: '', closeTime: '', isClosed: false, isOpenAll: true },
    { id: 6, value: 'Saturday', openTime: '', closeTime: '', isClosed: false, isOpenAll: true },
]

const accountOptions = [
    { value: 'bddff1b2-ffc7-48f4-b193-d751b1845b0e', label: 'Location' },
    { value: "5275e264-c6e0-4ca4-ab25-cb4168348477", label: "Retailer" },
    { value: '1eb5ba2c-e7de-421f-9c8d-1baafb751fc7', label: 'Partner' },
    { value: 'bf7a36fb-b1ba-407d-ad8c-5b9d563c4681', label: 'Store' },
]

const timeOptions = [
    { value: "00", label: '00' },
    { value: "01", label: '01' },
    { value: "02", label: '02' },
    { value: "03", label: '03' },
    { value: "04", label: '04' },
    { value: "05", label: '05' },
    { value: "06", label: '06' },
    { value: "07", label: '07' },
    { value: "08", label: '08' },
    { value: "09", label: '09' },
    { value: "10", label: '10' },
    { value: "11", label: '11' },
    { value: "12", label: '12' },
    { value: "13", label: '13' },
    { value: "14", label: '14' },
    { value: "15", label: '15' },
    { value: "16", label: '16' },
    { value: "17", label: '17' },
    { value: "18", label: '18' },
    { value: "19", label: '19' },
    { value: "20", label: '20' },
    { value: "21", label: '21' },
    { value: "22", label: '22' },
    { value: "23", label: '23' },
]

const expDaysOption = [
    { value: "1", label: "1 day" },
    { value: "2", label: "2 day" },
    { value: "3", label: "3 day" },
    { value: "4", label: "4 day" },
    { value: "5", label: "5 day" },
    { value: "6", label: "6 day" },
    { value: "7", label: "7 day" },
    { value: "8", label: "8 day" },
    { value: "9", label: "9 day" },
    { value: "10", label: "10 day" },
    { value: "11", label: "11 day" },
    { value: "12", label: "12 day" },
    { value: "13", label: "13 day" },
    { value: "14", label: "14 day" },
    { value: "15", label: "15 day" },
]

const notifyOptions = [
    { value: "1", label: "Daily from stock date" },
    { value: "2", label: "Every 2 days" },
    { value: "3", label: "Every 3 days" },
    { value: "4", label: "Every 4 days" },
    { value: "5", label: "Once 5 days" },
    { value: "-1", label: "Never" },
]

const langOptions = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "fr", label: "French" },
]

const taxOptions = [
    { value: 0, label: "Custom" },
    { value: 1, label: "Federal" },
    { value: 2, label: "State" },
    { value: 3, label: "VAT" },
]

const purgeOption=[
    { value: 7, label: "7" },
    { value: 30, label: "30" },
    { value: 60, label: "60" },
    { value: 90, label: "90" },
    { value: 180, label: "180" },
]


export { weekArray, kioskWeekArray, accountOptions, timeOptions, expDaysOption, notifyOptions, langOptions,taxOptions,purgeOption }