import React from 'react'
import BOX_STATUS from '../../../constant/BoxStatus';
import GroupTypes from '../../../constant/GroupTypes';
import { CiLock } from "react-icons/ci";

let nums = Array.from(Array(3).keys());
let nums1 = Array.from(Array(12).keys());
let elem = ['A', 'B', 'C'];
// let deactivatedBin = [];
let IsSaved;
let BinIds = [];

const Mini24Layout = ({ BinStatus, deactivatedBins, getPrescriptionDetails, Selectedbin,isViewPermissionAllowed }) => {

    const checkIsSaved = (binId) => {
        if (BinStatus && BinStatus.length && BinStatus.length > 0) {
            BinStatus.map((binitem) => {
                if (binitem.bin_id === binId) {
                    IsSaved = true;
                }
                else {
                    IsSaved = false;
                }
                return true;
            })
        }
        else {
            IsSaved = false;
        }
    }

    const openbin = (binId, status, groupTypeId) => {
        if(groupTypeId !==  GroupTypes.Pharmacy) {
            if (status === 'Assigned' || status === 'Stocked' || status === 'Hold') {
                getPrescriptionDetails(binId);
            }
            else {
                BinIds.map((item) => {
                    checkIsSaved(item);
                    if (item !== binId && IsSaved === false) {
                        var items = document.getElementById(item);
                        items.style.background = 'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
                    }
                    return true
                })
                BinIds = [];
                BinIds.push(binId);
                if (BinIds.length > 0) {
                    Selectedbin(binId);
                    var items = document.getElementById(binId);
                    items.style.background = 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)';
                }
            }
        }
    }
    return (
        <div className="container">
            <div className='row'>
                {nums1.map((n1) => {
                    return (
                        <div
                            key={n1}
                            className='row g-1 gx-1'
                        >
                            {nums.map((n) => {
                                if ((elem[n] === 'A' || elem[n] === 'B') && (n1 >= 0 && n1 <= 5)) {
                                    if (elem[n] === 'A') {
                                        return <div
                                            key={n}
                                            id={elem[n] + '' + (n1 + 1)}
                                            className='col-4'
                                        />
                                    } else {
                                        return <div
                                            key={n}
                                            id={elem[n] + '' + (n1 + 1)}
                                            className='col-4'
                                        />
                                    }
                                } else {
                                    if (elem[n] === 'A' || elem[n] === 'B' || elem[n] === 'C') {
                                        if (BinStatus && BinStatus.length && BinStatus.length > 0) {
                                            let BinKey = elem[n] + (n1 + 1)
                                            for (var i = 0; i < BinStatus.length; i++) {
                                                let groupTypeId = BinStatus[i]?.group_type_id;
                                                if (BinStatus[i].type_id === BOX_STATUS.STOCKED && BinStatus[i].bin_id === BinKey) {
                                                    return (
                                                        <div
                                                            title="Stocked"
                                                            data-toggle="tooltip"
                                                            className="tooltip-test text-dark col-4 text-center fw-bolder"
                                                        >
                                                            <div
                                                                id={elem[n] + '' + (n1 + 1)}
                                                                key={((elem[n] + ' ' + (n1 + 1)))}
                                                                style={{
                                                                    border: "1px solid gray",
                                                                    borderRadius: "24px",
                                                                    padding: '10px',
                                                                    background: 'linear-gradient(155deg, rgb(23,166,0) 0%, rgb(196,255,189) 90%)',
                                                                    pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                    cursor: BinStatus[i]?.group_type_id === GroupTypes.Pharmacy ? "not-allowed" : (isViewPermissionAllowed ? 'pointer' : 'not-allowed')
                                                                }}
                                                                onClick={() => openbin((elem[n] + '' + (n1 + 1)), 'Stocked', groupTypeId)}
                                                            >
                                                                {
                                                                    (BinStatus[i] && BinStatus[i].group_type_id) === GroupTypes.Pharmacy  ? <CiLock size='24px' color='#000' fontWeight={700} /> : null
                                                                }
                                                                &nbsp;{elem[n] + ' ' + (n1 + 1)}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else if (BinStatus[i].type_id === BOX_STATUS.ASSIGNED && BinStatus[i].bin_id === BinKey) {
                                                    return (
                                                        <div
                                                            title="Assigned"
                                                            data-toggle="tooltip"
                                                            className="tooltip-test text-primary col-4 text-center fw-bolder"
                                                        >
                                                            <div
                                                                id={elem[n] + '' + (n1 + 1)}
                                                                key={((elem[n] + ' ' + (n1 + 1)))}
                                                                style={{
                                                                    border: "1px solid gray",
                                                                    borderRadius: "24px",
                                                                    padding: '10px',
                                                                    background: 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)',
                                                                    pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                    cursor: BinStatus[i]?.group_type_id === GroupTypes.Pharmacy ? "not-allowed" : (isViewPermissionAllowed ? 'pointer' : 'not-allowed')
                                                                }}
                                                                onClick={() => openbin((elem[n] + '' + (n1 + 1)), 'Assigned', groupTypeId)}
                                                            >
                                                                {
                                                                    (BinStatus[i] && BinStatus[i].group_type_id) === GroupTypes.Pharmacy  ? <CiLock size='24px' color='#000' fontWeight={700} /> : null
                                                                }
                                                                &nbsp;{elem[n] + ' ' + (n1 + 1)}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else if (BinStatus[i].type_id === BOX_STATUS.HOLD && BinStatus[i].bin_id === BinKey) {
                                                    return (
                                                        <div
                                                            title="Hold"
                                                            data-toggle="tooltip"
                                                            className="tooltip-test text-dark col-4 text-center fw-bolder"
                                                        >
                                                            <div
                                                                id={elem[n] + '' + (n1 + 1)}
                                                                key={elem[n] + ' ' + (n1 + 1)}
                                                                style={{
                                                                    border: "1px solid gray",
                                                                    borderRadius: "24px",
                                                                    padding: '10px',
                                                                    background: 'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)',
                                                                    pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                    cursor: BinStatus[i]?.group_type_id === GroupTypes.Pharmacy ? "not-allowed" : (isViewPermissionAllowed ? 'pointer' : 'not-allowed')
                                                                }}
                                                                onClick={() => openbin((elem[n] + '' + (n1 + 1)), 'Hold', groupTypeId)}
                                                            >
                                                                {
                                                                    (BinStatus[i] && BinStatus[i].group_type_id) === GroupTypes.Pharmacy  ? <CiLock size='24px' color='#000' fontWeight={700} /> : null
                                                                }
                                                                &nbsp;{elem[n] + ' ' + (n1 + 1)}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }
                                            if (deactivatedBins && deactivatedBins.length && deactivatedBins.length > 0 && deactivatedBins.findIndex(D => D === (elem[n] + (n1 + 1))) > -1) {
                                                return (
                                                    <div
                                                        title="DEACTIVATE"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-primary col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem[n] + '' + (n1 + 1)}
                                                            key={elem[n] + ' ' + (n1 + 1)}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "24px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: BinStatus[i]?.group_type_id === GroupTypes.Pharmacy ? "not-allowed" : (isViewPermissionAllowed ? 'pointer' : 'not-allowed')
                                                            }}
                                                        >
                                                            {
                                                                (BinStatus[i] && BinStatus[i].group_type_id) === GroupTypes.Pharmacy  ? <CiLock size='24px' color='#000' fontWeight={700} /> : null
                                                            }
                                                            &nbsp;{elem[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        title="Available"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-primary col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem[n] + '' + (n1 + 1)}
                                                            key={((elem[n] + ' ' + (n1 + 1)))}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "24px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: BinStatus[i]?.group_type_id === GroupTypes.Pharmacy ? "not-allowed" : (isViewPermissionAllowed ? 'pointer' : 'not-allowed')
                                                            }}
                                                            onClick={() => openbin((elem[n] + '' + (n1 + 1)), null, BinStatus[i]?.group_type_id)}
                                                        >
                                                            {
                                                                (BinStatus[i] && BinStatus[i].group_type_id) === GroupTypes.Pharmacy  ? <CiLock size='24px' color='#000' fontWeight={700} /> : null
                                                            }
                                                            &nbsp;{elem[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        } else {
                                            if (deactivatedBins.length > 0 && deactivatedBins.findIndex(D => D === (elem[n] + (n1 + 1))) > -1) {
                                                return (
                                                    <div
                                                        title="DEACTIVATE"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-dark col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem[n] + '' + (n1 + 1)}
                                                            key={elem[n] + ' ' + (n1 + 1)}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "24px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: BinStatus[i]?.group_type_id === GroupTypes.Pharmacy ? "not-allowed" : (isViewPermissionAllowed ? 'pointer' : 'not-allowed')
                                                            }}
                                                        >
                                                            {
                                                                (BinStatus[i] && BinStatus[i].group_type_id) === GroupTypes.Pharmacy  ? <CiLock size='24px' color='#000' fontWeight={700} /> : null
                                                            }
                                                            &nbsp;{elem[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        title="Available"
                                                        data-toggle="tooltip"
                                                        className="tooltip-test text-primary col-4 text-center fw-bolder"
                                                    >
                                                        <div
                                                            id={elem[n] + '' + (n1 + 1)}
                                                            key={((elem[n] + ' ' + (n1 + 1)))}
                                                            style={{
                                                                border: "1px solid gray",
                                                                borderRadius: "24px",
                                                                padding: '10px',
                                                                background: 'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)',
                                                                pointerEvents: isViewPermissionAllowed ? 'auto' : 'none',
                                                                cursor: BinStatus[i]?.group_type_id === GroupTypes.Pharmacy ? "not-allowed" : (isViewPermissionAllowed ? 'pointer' : 'not-allowed')
                                                            }}
                                                            onClick={() => openbin((elem[n] + '' + (n1 + 1)), null, BinStatus[i]?.group_type_id)}
                                                        >
                                                            {
                                                                (BinStatus[i] && BinStatus[i].group_type_id) === GroupTypes.Pharmacy  ? <CiLock size='24px' color='#000' fontWeight={700} /> : null
                                                            }
                                                            &nbsp;{elem[n] + ' ' + (n1 + 1)}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                    }
                                }
                                return true
                            })
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default Mini24Layout
