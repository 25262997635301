const locationOption = [
    { value: "a5bd2ad0-0780-42cd-a270-7e76c4f20500", label: 'Kiosk' },
    { value: "40a40496-1c2a-4f46-b00a-f210d34f24cd", label: "Curbside" },
    { value: "567c43b9-dd50-4dd0-b293-2f8ebceb035c", label: "Counter" },
    { value: "93d79d62-523a-4d4f-851f-6866d38cb340", label: "Courier" },
    { value: "7e8e5632-6187-4d9b-9528-adf14825e859", label: "Bedside" },
    { value: "70c950a2-85ba-4dad-885c-ef04672eb815", label: "Other" },
];

const directionOpt = [
    { value: 1, label: "Left" },
    { value: 2, label: "Right" }
]

const BOX_TYPE = {
    Locker: 'd5e3af71-ab56-44c4-bf4d-de7b53e47f38',
    L60: '415f008a-2913-49ea-9c62-e830f2c07008',
    Cool: 'fdfe7cf1-d266-4e1a-b26d-87423ce2b56b',
    HD36: '68534189-6ac4-4fdb-ae16-374d7870fd92',
    M22: 'eb5e33fc-b744-46fb-9ee6-802c602977c1',
    M24: '9fa0079a-3d98-4942-88c8-3f6537d68e48',
    FASTLANE: 'e9da3c38-b838-4054-8b74-dcfb7d2713d8'
};

const portOpt = [
    { value: "COM2", label: "COM 2" },
    { value: "COM3", label: "COM 3" },
    { value: "COM4", label: "COM 4" },
    { value: "COM5", label: "COM 5" },
    { value: "COM6", label: "COM 6" }
]

const COOL_UNIT_TEMP = {
    MAX_TEMP: '80',
    MIN_TEMP: '20'
};



export { locationOption, directionOpt, BOX_TYPE, portOpt, COOL_UNIT_TEMP };