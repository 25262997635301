/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import request from "./../../../services/AxiosInstance";
import { Button, Modal, Overlay } from "react-bootstrap";
import { Loader } from "../../components/bootstrap/Loader";
import { useNavigate } from "react-router-dom";
import ManageGroups from "./ManageGroups";
import { AgGridReact } from "ag-grid-react";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import "ag-grid-enterprise";

ModuleRegistry.registerModules([ServerSideRowModelModule]);
let TransactionFilterObj = {
  searchtext: "",
  pageNo: 0,
  pageSize: 5,
  sortOrder: "desc",
  sortColumn: "created_on",
  tableName: "",
  parent_account_id: sessionStorage.getItem("parentAccountId"),
};

const TransactionSortObj = {
  name: { tableName: "groups", sortColumn: "name" },
  username: { tableName: "groups", sortColumn: "parent_account_id" },
};

const ManageGroupsList = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const [groupData, setgroupData] = useState([]);
  const [groupRowData, setgroupRowData] = useState("");
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [rowPerPage] = useState(5);

  const handleGroupDelete = async () => {
    const rowIdToDelete = groupRowData && groupRowData[0].id;
    const userId = JSON.parse(sessionStorage.getItem("user")).username;
    setLoader(true);
    const resDelete = await request(
      "delete",
      `groups?id=${rowIdToDelete}&userId=${userId}`,
      {
        rowIdToDelete,
      }
    );

    if (resDelete.status !== 200) {
      toast.error(resDelete.message);
      setLoader(false);
      return;
    }
    if (resDelete.status === 200) {
      const newData = groupData.filter((x) => x.id !== rowIdToDelete);
      setgroupData(newData);
      setDeleteModal(false);
      setLoader(false);
      toast.success("Group deleted successfully");
    }
  };

  const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

  const onPaginationChanged = (params) => {
    const newPageSize = params.api.paginationGetPageSize();
    if (rowPerPage !== newPageSize) {
      TransactionFilterObj.pageSize = newPageSize;
    }
  };
  const gridOptions = {
    rowModelType: "serverSide",
    gridBodyCls: "ag-layout-auto-height",
  };

  const onGridReady = (params) => {
    const datasource = getServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
  };

  const getServerSideDatasource = () => {
    return {
      getRows(params) {
        TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
        const filterObject = params.request && params.request.filterModel;
        const filterColumns = Object.keys(filterObject);

        if (filterObject) {
          if (filterColumns.length === 0) {
            TransactionFilterObj.searchObject = "";
          } else {
            Object.keys(filterObject).forEach((colId) => {
              TransactionFilterObj.searchObject = filterObject;
            });
          }
        }

        if (params.request.sortModel.length > 0) {
          TransactionFilterObj.tableName =
            TransactionSortObj[params.request.sortModel[0].colId].tableName;
          TransactionFilterObj.sortColumn =
            TransactionSortObj[params.request.sortModel[0].colId].sortColumn;
          TransactionFilterObj.sortOrder = params.request.sortModel[0].sort;
        }

        apiCall();

        function apiCall() {
          request("post", "groups/list", TransactionFilterObj)
            .then((response) => {
              if (response && response.data && response.data.results) {
                if (response.data.results.length > 0) {
                  params.success({
                    rowData: response.data.results,
                    rowCount: response.data.total,
                  });
                  setgroupData(response.data.results);
                } else {
                  params.success({ rowData: [], rowCount: 0 });
                  setgroupData([]);
                }
              } else {
                params.success({ rowData: [], rowCount: 0 });
                setgroupData([]);
              }
            })
            .catch((error) => {
              console.error(error);
              params.fail();
              setgroupData([]);
            });
        }
      },
    };
  };

  const ActionCellRenderer = ({ data }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleView = () => {
      if (isView) {
        const _groupData = groupData.filter((val) => val.id === data.id);
        if (_groupData.length > 0) {
          setType("View");
          setgroupRowData(_groupData);
        } else {
          toast.error("Data Not Found!");
        }
      }
    };

    const handleEdit = () => {
      if (isEdit) {
        const _groupData = groupData.filter((val) => val.id === data.id);
        if (_groupData.length > 0) {
          setType("Edit");
          setgroupRowData(_groupData);
        } else {
          toast.error("Data Not Found!");
        }
      }
    };

    const handleDelete = () => {
      if (isDelete) {
        const _groupData = groupData.filter((val) => val.id === data.id);
        if (_groupData.length > 0) {
          setgroupRowData(_groupData);
          setDeleteModal(true);
        } else {
          toast.error("Data Not Found!");
        }
      }
    };

    return (
      <>
        <Button
          variant="link"
          className="pr-0"
          ref={target}
          onClick={() => setShow(!show)}
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </Button>
        <Overlay target={target.current} show={show} placement="right">
          {({
            placement: _placement,
            arrowProps: _arrowProps,
            show: _show,
            popper: _popper,
            hasDoneInitialMeasure: _hasDoneInitialMeasure,
            ...props
          }) => (
            <div className="action-btn" {...props}>
              <span
                className={`action-link ${isView ? "" : "cursor-na"}`}
                onClick={() => handleView()}
              >
                View
              </span>
              <span
                className={`action-link ${isEdit ? "" : "cursor-na"}`}
                onClick={() => handleEdit()}
              >
                Edit
              </span>
              <span
                className={`action-link ${isDelete ? "" : "cursor-na"}`}
                onClick={() => handleDelete()}
              >
                Delete
              </span>
            </div>
          )}
        </Overlay>
      </>
    );
  };

  const TableHeader = [
    { headerName: "Name", field: "name" },
    {
      headerName: "Associated Account",
      field: "parentAccount.name",
      sortable: false,
      filter: false,
    },
    {
      headerName: "Associated Type",
      field: "type.type",
      sortable: false,
      filter: false,
    },
    {
      headerName: "Actions",
      field: "Actions",
      filter: false,
      sortable: false,
      cellRenderer: ActionCellRenderer,
    },
  ];

  const handlePermissions = () => {
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    if (!permissions || permissions.length === 0) {
      setIsAdd(false);
      setIsView(false);
      setIsEdit(false);
      setIsDelete(false);
      return;
    }

    const manageBoxes = permissions.filter(
      (item) => item.permission_entity_type === "Manage Groups"
    );

    if (manageBoxes.length === 0) {
      setIsAdd(false);
      setIsView(false);
      setIsEdit(false);
      setIsDelete(false);
      return;
    }

    setIsAdd(
      manageBoxes.some(
        (item) => item.name.trim() === "Add" && item.is_allowed === 1
      )
    );
    setIsView(
      manageBoxes.some(
        (item) => item.name.trim() === "View" && item.is_allowed === 1
      )
    );
    setIsEdit(
      manageBoxes.some(
        (item) => item.name.trim() === "Modify" && item.is_allowed === 1
      )
    );
    setIsDelete(
      manageBoxes.some(
        (item) => item.name.trim() === "Delete" && item.is_allowed === 1
      )
    );
  };

  useEffect(() => {
    handlePermissions();
  }, []);

  if (type === "View" || type === "Edit") {
    return (
      <>
        <ManageGroups rowData={groupRowData} type={type} setType={setType} />
      </>
    );
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <div className="text-end mb-3">
            <Button
              onClick={() => navigate("/group")}
              disabled={!isAdd}
              className="btn-sm btn-primary btn-rounded"
            >
              <i className="fa-solid fa-users" />
            </Button>
          </div>
          <div className="row">
            <div
              id="myGrid"
              className={"ag-theme-alpine"}
              style={{ boxSizing: "border-box", width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                sideBar={false}
                suppressMenuHide={true}
                columnDefs={TableHeader}
                defaultColDef={{
                  flex: 1,
                  minWidth: 150,
                  floatingFilter: true,
                  filter: "agTextColumnFilter",
                  filterParams: {
                    filterOptions: [
                      {
                        displayKey: "searchtext",
                        displayName: "Search Text",
                        predicate: (cellValue) => cellValue,
                      },
                    ],
                    maxNumConditions: 1,
                  },
                  resizable: true,
                  sortable: true,
                  menuTabs: ["generalMenuTab", "columnsMenuTab"],
                }}
                pagination={true}
                paginationPageSize={20}
                paginationPageSizeSelector={paginationPageSizeSelector}
                cacheBlockSize={20}
                maxBlocksInCache={0}
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                onPaginationChanged={onPaginationChanged}
                frameworkComponents={{
                  actionCellRenderer: ActionCellRenderer,
                }}
              />
            </div>
            <Modal className="fade" show={deleteModal} centered>
              <Modal.Header>
                <Modal.Title>Are you sure you want to delete?</Modal.Title>
              </Modal.Header>
              <Modal.Body>{groupRowData && groupRowData[0].name}</Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-sm"
                  variant="danger light"
                  onClick={() => setDeleteModal(false)}
                >
                  Close
                </Button>
                <Button
                  className="btn-sm"
                  variant="primary"
                  onClick={() => handleGroupDelete()}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageGroupsList;
