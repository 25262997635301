/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ConfigureAmplify from '../../common/awsConfig ';
import { Auth } from 'aws-amplify';
import request from '../../services/AxiosInstance';
import { toast } from 'react-toastify';
import { BtnLoader } from '../components/bootstrap/Loader';
import logo from "../../images/ilocalboxlogo.png"

function ResetPassword({ user, setResetPassword }) {
    const navigate = useNavigate();
    const [loader, setLoader] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState({ email: false, password: false, confirmPassword: false });

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;

    const handleValidation = () => {
        if (!email) {
            setError(prevState => ({ ...prevState, email: true }));
            setErrorMessage("Email is Required")
            return false;
        }
        if (!password) {
            setError(prevState => ({ ...prevState, password: true }));
            setErrorMessage("Password is Required")
            return false;
        }
        if (!confirmPassword) {
            setError(prevState => ({ ...prevState, confirmPassword: true }));
            setErrorMessage("Confirm Password is Required")
            return false;
        }
        if (!passwordRegex.test(password)) {
            setError(prevState => ({ ...prevState, password: true }));
            setErrorMessage("Password must be 8 characters or more, with at least one uppercase letter, one lowercase letter, one digit, and one special character.")
            return false;
        }

        if (password !== confirmPassword) {
            setError(prevState => ({ ...prevState, confirmPassword: true }));
            setErrorMessage("Confirm Password and Password should be same.")
            return false;
        }
        setError(false);
        return true;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const isValid = handleValidation();
        if (!isValid) { return; }
        setError({ email: false, password: false, confirmPassword: false });
        setLoader(true)

        const _name = user && user.challengeParam && user.challengeParam.userAttributes && user.challengeParam.userAttributes.name
        const userName = user.username
        Auth.completeNewPassword(user, password, { name: _name }).then(async () => {
            let userObj = {
                username: userName,
            }
            const resetDateRes = await request('post', 'UpdateResetPWDate', userObj)
            if (resetDateRes.message) {
                toast.error(resetDateRes.message)
                setLoader(false)
                return
            }
            toast.success("Password reset successfully")
            setLoader(false);
            setResetPassword(false)
            navigate('/')
        }).catch((err) => {
            toast.error(err.message);
            setLoader(false);
            return;
        })

    }

    useEffect(() => {
        ConfigureAmplify()
    }, [])

    return (
        <div className='authincation h-100 p-meddle'>
            <div className='container h-100'>
                <div className='row justify-content-center h-100 align-items-center'>
                    <div className='col-md-6'>
                        <div className='authincation-content'>
                            <div className='row no-gutters'>
                                <div className='col-xl-12'>
                                    <div className='auth-form'>
                                        <div className='d-flex justify-content-center'>
                                            <img style={{ height: "60px" }} src={logo} alt="logo" />
                                        </div>
                                        <h4 className='text-center mb-4 '>Welcome! Please confirm your email and update your password.</h4>
                                        <form onSubmit={onSubmit}>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Email</strong>
                                                </label>
                                                <input type='text' className='form-control'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                {error.email && <div className="text-danger fs-12">{errorMessage}</div>}
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>New Password</strong>
                                                </label>
                                                <input type="password" className="form-control"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                {error.password && <div className="text-danger fs-12">{errorMessage}</div>}
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Confirm New Password</strong>
                                                </label>
                                                <input type="password" className="form-control"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                {error.confirmPassword && <div className="text-danger fs-12">{errorMessage}</div>}
                                            </div>
                                            <div className='text-center mt-4'>
                                                <button disabled={loader} type="submit" className="btn btn-primary btn-block mb-2">
                                                    {loader ? <BtnLoader /> : "Reset Password"}
                                                </button>
                                                <button disabled={loader} onClick={() => setResetPassword(false)} className="btn btn-primary btn-block mb-2">
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
