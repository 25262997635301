/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Loader } from "../../components/bootstrap/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ManageRole = ({ rowData, type, setType }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [roleName, setRoleName] = useState("");

  const handleClear = () => {
    setRoleName("");
  };

  const handleSubmit = async () => {
    setLoader(true);
    if ([undefined, "undefined", null, "null", ""].includes(roleName)) {
      toast.error("Role name is required.");
      setLoader(false);
      return;
    }
    const postObj = {
      name: roleName,
    };
    if (!["View", "Edit"].includes(type)) {
      const result = fetch(process.env.REACT_APP_BASEURL + "role-management", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
              .jwtToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postObj),
      });
      result
        .then((result) => {
          result.json().then((res) => {
            setLoader(false);
            if ([201, "201"].includes(res?.status)) {
              handleClear();
              toast.success(res?.message);
              navigate("/roles-list");
            } else {
              toast.error(res.message);
              return;
            }
          });
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.message);
        });
    } else {
      let [data] = rowData;
      if ([undefined, "undefined", null, "null", ""].includes(roleName)) {
        toast.error("Role name is required.");
        setLoader(false);
        return;
      }
      const result = fetch(
        `${process.env.REACT_APP_BASEURL}role-management?id=${data.id}`,
        {
          method: "PUT",
          withCredentials: true,
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(sessionStorage.getItem("user")).signInUserSession
                .idToken.jwtToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postObj),
        }
      );
      result
        .then((result) => {
          result.json().then((res) => {
            setLoader(false);
            if ([200, "200"].includes(res?.status)) {
              handleClear();
              setType("")
              toast.success(res?.message);
              navigate("/roles-list");
            } else {
              toast.error(res.message);
              return;
            }
          });
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    if (rowData && rowData.length > 0) {
      let [data] = rowData;
      setRoleName(data.name);
    }
  }, []);

  return (
    <div className="position-relative">
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <h4 className="my-3 fw-bold">
            {type === "View"
              ? "Role"
              : type === "Edit"
              ? "Edit Role"
              : "Add Role"}
          </h4>
        </div>
        <div className="col-xl-10 col-lg-10">
          <div className="row">
            <div className="col-xl-4 col-lg-4">
              <div className="form-group mb-3">
                <label>Name</label>
                <input
                  type="text"
                  value={roleName}
                  disabled={type === "View"}
                  onChange={(e) => setRoleName(e.target.value)}
                  className="form-control  form-control-sm fs-16"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-12 text-end">
          <Button
            onClick={() =>
              ["View", "Edit"].includes(type)
                ? setType("")
                : navigate("/roles-list")
            }
            className="me-2 btn-sm"
          >
            Cancel
          </Button>
          {type !== "View" ? (
            <Button
              onClick={() => handleSubmit()}
              className="me-2 btn-sm"
              disabled={type === "View"}
            >
              {type === "Edit" ? "Update" : "Add"}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageRole;
