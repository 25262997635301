const CONNECTION_TYPE = {
    UNPLUGGED: '01395578-9dd2-49f3-a0a5-da7654225488',
    ILOCALBOX_PHARMACY: '4123a10d-b54d-457d-9ff3-c61899c875ee',
    QS1: 'baf57be6-e81f-4a2e-8440-83a59bf96a42',
    PIONEERRX:'9e38fef1-db0e-4217-88b6-61de3a1377d4',
    EPIC:'e458ec5c-cbd7-4a22-a353-d938f351edb0',
    MCKESSONERX: '916481bb-f0d8-4530-9783-b0194664deee',
    MICROMERCHANTS: '1d9e4227-597d-4c06-a4d5-3ef4a3dd5f99',
    RX30: 'dd6d95d6-2104-4cc7-8c42-0a672bb19446',
    MEDBANK: '9fa8c2aa-7ab0-4a82-b4dd-c347d39a4dfb',
    MSCRIPTS: 'fbffb34b-3be2-44a9-b02a-abfe7fb94448',
    EPIC_WITH_MTLS: 'd63de121-6814-459e-a493-19ae902707ec',
    LIBERTY_SOFTWARE :"e918d10d-2c4a-4251-a6dc-958c9a316679",
    THIRD_PARTY: "90504591-e910-4517-a51e-34ba37bea7e8"
  };

 export default CONNECTION_TYPE; 