/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useState } from "react";
import request from "./../../../services/AxiosInstance";
import { AgGridReact } from "ag-grid-react";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import "ag-grid-enterprise";

ModuleRegistry.registerModules([ServerSideRowModelModule]);
let TransactionFilterObj = {
  searchtext: "",
  pageNo: 0,
  pageSize: 5,
  sortOrder: "desc",
  sortColumn: "created_on",
  tableName: "",
  parentAccountId: sessionStorage.getItem("parentAccountId"),
};

const TransactionSortObj = {
  name: { tableName: "groups", sortColumn: "name" },
  username: { tableName: "groups", sortColumn: "parent_account_id" },
};

const ManageGroupUsersList = ({ rowData }) => {
  const gridRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [groupData, setgroupData] = useState([]);
  const [rowPerPage] = useState(5);

  const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

  const onPaginationChanged = (params) => {
    const newPageSize = params.api.paginationGetPageSize();
    if (rowPerPage !== newPageSize) {
      TransactionFilterObj.pageSize = newPageSize;
    }
  };
  const gridOptions = {
    rowModelType: "serverSide",
    gridBodyCls: "ag-layout-auto-height",
  };

  const onGridReady = (params) => {
    const datasource = getServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
  };

  const getServerSideDatasource = () => {
    return {
      getRows(params) {
        TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
        const filterObject = params.request && params.request.filterModel;
        const filterColumns = Object.keys(filterObject);

        if (filterObject) {
          if (filterColumns.length === 0) {
            TransactionFilterObj.searchObject = "";
          } else {
            Object.keys(filterObject).forEach((colId) => {
              TransactionFilterObj.searchObject = filterObject;
            });
          }
        }

        if (params.request.sortModel.length > 0) {
          TransactionFilterObj.tableName =
            TransactionSortObj[params.request.sortModel[0].colId].tableName;
          TransactionFilterObj.sortColumn =
            TransactionSortObj[params.request.sortModel[0].colId].sortColumn;
          TransactionFilterObj.sortOrder = params.request.sortModel[0].sort;
        }

        apiCall();

        function apiCall() {
          if (rowData && rowData.length > 0) {
            let [data] = rowData;
            TransactionFilterObj.groupId = data?.id;

            request("post", "/groups/users/by/group", TransactionFilterObj)
              .then((response) => {
                if (response && response.data && response.data.results) {
                  if (response.data.results.length > 0) {
                    params.success({
                      rowData: response.data.results,
                      rowCount: response.data.total,
                    });
                    setgroupData(response.data.results);
                  } else {
                    params.success({ rowData: [], rowCount: 0 });
                    setgroupData([]);
                  }
                } else {
                  params.success({ rowData: [], rowCount: 0 });
                  setgroupData([]);
                }
              })
              .catch((error) => {
                console.error(error);
                params.fail();
                setgroupData([]);
              });
          }
        }
      },
    };
  };

  const TableHeader = [
    { headerName: "Name", field: "name" },
    {
      headerName: "Username",
      field: "username",
      sortable: false,
      filter: false,
    },
    {
      headerName: "Associated Account",
      field: "store_name",
      sortable: false,
      filter: false,
    },
  ];

  return (
    <div className="row">
      <div
        id="myGrid"
        className={"ag-theme-alpine"}
        style={{ boxSizing: "border-box", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          sideBar={false}
          suppressMenuHide={true}
          columnDefs={TableHeader}
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: [
                {
                  displayKey: "searchtext",
                  displayName: "Search Text",
                  predicate: (cellValue) => cellValue,
                },
              ],
              maxNumConditions: 1,
            },
            resizable: true,
            sortable: true,
            menuTabs: ["generalMenuTab", "columnsMenuTab"],
          }}
          pagination={true}
          paginationPageSize={20}
          paginationPageSizeSelector={paginationPageSizeSelector}
          cacheBlockSize={20}
          maxBlocksInCache={0}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          onPaginationChanged={onPaginationChanged}
        />
      </div>
    </div>
  );
};

export default ManageGroupUsersList;
