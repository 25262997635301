/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Tab, Nav, Button, Accordion, Modal } from 'react-bootstrap'
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import request from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { weekArray, kioskWeekArray, timeOptions, expDaysOption, notifyOptions, taxOptions, purgeOption } from "../../../constant/AccountConstant"
import CONNECTION_TYPE from "../../../constant/connectionTypes";
import { BtnLoader, Loader } from "../../components/bootstrap/Loader";
import { Auth } from "aws-amplify";
import ConfigureAmplify from "../../../common/awsConfig ";
import { maskPhoneNumber, timeMask } from "../../../helper/helper";

const Account = ({ rowData, type, setType }) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [accountData, setAccountData] = useState('');
    const [accountValues, setAccountValues] = useState([]);
    const [parentId, setParentId] = useState('')
    const [accountName, setAccountName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [accountImg, setAccountImg] = useState('');
    const [closedImg, setClosedImg] = useState('');
    const [closedMsg, setClosedMsg] = useState('');
    const [accountHoursOfOperaionsArray, setAccountHoursOfOperaionsArray] = useState(weekArray)
    const [kioskHoursOfOperaionsArray, setKioskHoursOfOperaionsArray] = useState(kioskWeekArray)
    const [accountContactPersons, setAccountContactPersons] = useState([{ first_name: '', last_name: '', email: '', phone: '', isdefault: false }]);
    const [brandName, setBrandName] = useState('');
    const [brandPhone, setBrandPhone] = useState('');
    const [brandWebsite, setBrandWebsite] = useState('');
    const [brandFax, setBrandFax] = useState('');
    const [brandEmail, setBrandEmail] = useState('');
    const [brandHelp, setBrandHelp] = useState('');
    const [brandAfterHourPhone, setBrandAfterHourPhone] = useState('');
    const [brandPolicy, setBrandPolicy] = useState('');
    const [brandImg, setBrandImg] = useState('');
    const [brandTerm, setBrandTerm] = useState('');
    const [rxExpDays, setRxExpDays] = useState('');
    const [patientNotify, setPatientNotify] = useState('');
    const [timeZoneOpt, setTimeZoneOpt] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [timeNotify, setTimeNotify] = useState('');
    const [taxCode, setTaxCode] = useState('');
    const [taxRate, setTaxRate] = useState('');
    const [errors, setErrors] = useState({});
    const [isPurge, setIsPurge] = useState(false);
    const [purgeDay, setPurgeDay] = useState('');
    const [isPurgeAuth, setIsPurgeAuth] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isPurgeFinal, setIsPurgeFinal] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [isDayToRetain, setIsDayToRetain] = useState(false);
    const [retainDays,setRetainDays]=useState('')

    const handleReset = () => {
        setAccountValues([]);
        setParentId('');
        setAccountName('');
        setAddressLine1('');
        setAddressLine2('');
        setCity('');
        setState('');
        setZip('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setAccountImg('');
        setClosedImg('');
        setClosedMsg('');
        setAccountHoursOfOperaionsArray(weekArray);
        setKioskHoursOfOperaionsArray(kioskWeekArray);
        setAccountContactPersons([{ first_name: '', last_name: '', email: '', phone: '', isdefault: false }]);
        setBrandName('');
        setBrandPhone('');
        setBrandWebsite('');
        setBrandFax('');
        setBrandEmail('');
        setBrandHelp('');
        setBrandAfterHourPhone('');
        setBrandPolicy('');
        setBrandImg('');
        setBrandTerm('');
        setRxExpDays('');
        setPatientNotify('');
        setTimeZoneOpt('');
        setTimeZone('');
        setTimeNotify('');
        setTaxCode('');
        setTaxRate('');
    }

    const onDrop = useCallback((acceptedFiles, type) => {
        const file = acceptedFiles[0];
        const fileWithPreview = Object.assign(file, {
            preview: URL.createObjectURL(file)
        });
        if (type === 'account') { setAccountImg(fileWithPreview) }
        if (type === 'closed') { setClosedImg(fileWithPreview) }
        if (type === 'brand') { setBrandImg(fileWithPreview) }
    }, [])

    const { getRootProps: getAccountRootProps } = useDropzone({ onDrop: files => onDrop(files, "account") });
    const { getRootProps: getClosedRootProps } = useDropzone({ onDrop: files => onDrop(files, "closed") });
    const { getRootProps: getBrandRootProps } = useDropzone({ onDrop: files => onDrop(files, "brand") });

    const handleRemoveImage = (type) => {
        if (type === 'account') { setAccountImg(null) }
        if (type === 'closed') { setClosedImg(null) }
        if (type === 'brand') { setBrandImg(null) }
    }


    const addContactRow = () => {
        const contactObj = {
            id: accountContactPersons.length + 1,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            isdefault: false
        }
        const newContact = [...accountContactPersons, contactObj];
        setAccountContactPersons(newContact)
    }

    const removeContactRow = (removeIndex) => {
        const updatedContact = accountContactPersons.filter((_, index) => index !== removeIndex);
        setAccountContactPersons(updatedContact)
    };

    const onChangeContactProperty = (event, index, propertyName) => {
        const updatedContacts = accountContactPersons && accountContactPersons.map((item, sidx) => {
            if (index !== sidx) return item;
            return {
                id: index,
                first_name: propertyName === "first_name" ? event.target.value : item.first_name,
                last_name: propertyName === "last_name" ? event.target.value : item.last_name,
                email: propertyName === "email" ? event.target.value : item.email,
                phone: propertyName === "phone" ? event.target.value : item.phone,
                isdefault: propertyName === "isdefault" ? event.target.checked : item.isdefault,
            };
        });
        setAccountContactPersons(updatedContacts)
    };

    const getAccounts = async () => {
        const accResData = await request('get', 'accounts');
        if (accResData.message) {
            toast.error(accResData.message)
            return;
        }
        const accountValues = accResData.map((account) => {
            return { value: account.id, label: account.name };
        });
        setAccountValues(accountValues)
        setParentId(accountValues[0])
    }

    const convertAccountHoursOfOperationsObject = async (selectedObject) => {
        if (![undefined, 'undefined', null, 'null', ''].includes(selectedObject)) {
            const {
                id,
                account_id,
                created_on,
                deleted_on,
                modified_on,
                is_deleted,
                is_enabled,
                closed_msg,
                closedimg_url,
                hop_type,
                box_id,
                lunch_start,
                lunch_end,
                ...filteredObject
            } = selectedObject;
            return filteredObject;
        }
    };

    const hanldeAccountHoursOfOperations = async (selectedObject) => {
        let AccountHoursOfOperationArray = [];
        if (
            selectedObject !== '' &&
            selectedObject !== null &&
            selectedObject !== 'null' &&
            selectedObject !== undefined &&
            selectedObject !== 'undefined'
        ) {
            const Keys = Object.keys(selectedObject);
            const Values = Object.values(selectedObject);
            if (Keys && Keys.length && Keys.length > 0) {
                for (let i = 0; i < Keys.length; i++) {
                    const KeyName = Keys && Keys[i];
                    const HoursOfTime = Values && Values[i] && Values[i].split(',');
                    AccountHoursOfOperationArray.push({
                        id: i,
                        value: KeyName[0].toUpperCase() + KeyName.slice(1),
                        openTime: HoursOfTime && HoursOfTime[0],
                        closeTime: HoursOfTime && HoursOfTime[1],
                        isClosed: HoursOfTime && HoursOfTime[2],
                        isOpenAll: HoursOfTime && HoursOfTime[3],
                    });
                }
            }
        }
        return AccountHoursOfOperationArray;
    };

    const handleKioskHour = async (kiosk_hoursof_operations) => {
        const KioskHOPObject = await convertAccountHoursOfOperationsObject(kiosk_hoursof_operations[0]);
        const KioskHoursOfOperations = await hanldeAccountHoursOfOperations(KioskHOPObject);
        setKioskHoursOfOperaionsArray(KioskHoursOfOperations)
    }

    const handleAccountHour = async (account_hoursof_operations) => {
        const AccountsHOPObject = await convertAccountHoursOfOperationsObject(account_hoursof_operations);
        const AccountHoursOfOperations = await hanldeAccountHoursOfOperations(AccountsHOPObject);
        setAccountHoursOfOperaionsArray(AccountHoursOfOperations)
    }

    const handleAccountTimeChange = (event, index, propertyName) => {
        const HoursOfOperation = accountHoursOfOperaionsArray.map((item, idx) => {
            if (idx !== index) return item;
            return {
                id: idx,
                value: item.value,
                openTime: propertyName === "openTime" ? event.target.value : item.openTime,
                closeTime: propertyName === "closeTime" ? event.target.value : item.closeTime,
            };
        });
        setAccountHoursOfOperaionsArray(HoursOfOperation);
    };

    const handleKioskTimeChange = (event, index, propertyName) => {
        const updatedHoursOfOperation = kioskHoursOfOperaionsArray && kioskHoursOfOperaionsArray.map((item, idx) => {
            if (idx !== index) return item;
            return {
                id: idx,
                value: item.value,
                openTime: propertyName === "openTime" ? event.target.value : item.openTime,
                closeTime: propertyName === "closeTime" ? event.target.value : item.closeTime,
                isClosed: propertyName === "isClosed" ? event.target.checked : item.isClosed,
                isOpenAll: propertyName === "isOpenAll" ? event.target.checked : item.isOpenAll
            };
        });
        setKioskHoursOfOperaionsArray(updatedHoursOfOperation);
    };

    const handleSubmit = async () => {
        setLoader(true);
        let data = new FormData();
        data.append('name', accountName);
        data.append('parent_id', parentId && parentId.value);
        data.append('type_id', '5275e264-c6e0-4ca4-ab25-cb4168348477');
        data.append('avatar', accountImg);
        data.append('address1', addressLine1);
        data.append('address2', addressLine1);
        data.append('city', city);
        data.append('state', state);
        data.append('zip', zip);
        data.append('first_name', firstName);
        data.append('last_name', lastName);
        data.append('email', email);
        data.append('phone', phone);
        data.append('accContactPerson', JSON.stringify(accountContactPersons))
        data.append('cloesdimg', closedImg);
        data.append('closedMsg', closedMsg);
        data.append('HopType', 'AccountLevel');
        data.append('login_type', 'DEFAULT')
        data.append('accountHoursOfOperations', JSON.stringify(accountHoursOfOperaionsArray))
        data.append('kiosk_hoursof_operations', JSON.stringify(kioskHoursOfOperaionsArray))

        data.append('account_connections_id', CONNECTION_TYPE.ILOCALBOX_PHARMACY);
        data.append('brandname', brandName);
        data.append('generalphone', brandPhone);
        data.append('after_hour_phone', brandAfterHourPhone);
        data.append('website', brandWebsite);
        data.append('fax', brandFax);
        data.append('generalemail', brandEmail);
        data.append('helpLink', brandHelp);
        data.append('privacyPolicyLink', brandPolicy);
        data.append('privtermsAndConditionsLinkacyPolicyLink', brandTerm);
        data.append('blogo', brandImg);

        data.append('Expiration_Day', rxExpDays && rxExpDays.value);
        data.append('pick_up_reminder_day', patientNotify && patientNotify.value);
        data.append('timezone', timeZone && timeZone.value);
        data.append('time_to_notify', timeNotify && timeNotify.value);

        data.append('purge_patient_images_toggle', isPurge);
        data.append('purge_patient_images_days_count', purgeDay && purgeDay.value);
        data.append('is_days_to_retain',isDayToRetain);
        data.append('retain_days',retainDays);

        data.append('tax_code', taxCode && taxCode.value);
        data.append('tax_rate', taxRate);

        data.append('prefix_length', 0);
        data.append('store_number_length', 0);
        data.append('rx_number_length', 0);
        data.append('fill_number_length', 0);
        data.append('partial_fill_length', 0);
        data.append('Health_and_info', JSON.stringify([]))
        data.append('xWebId', 'NA');
        data.append('auth_Key', 'NA');
        data.append('terminalId', 'NA');
        data.append('tokenxgbapikey', 'NA');
        data.append('transxgbapisecret', 'NA');
        data.append('allow_payment', 'NA');
        data.append('authToken', 'NA');
        data.append('authSecret', 'NA');
        data.append('merchant_id', 'NA');
        data.append('hpp_identifier', 'NA');

        if (!['View', 'Edit'].includes(type)) {
            let objAvailability = { accountName: accountName };
            const accAvailability = await request('post', 'CheckAccountAvailability', objAvailability)
            if (accAvailability.ErrorMessage) {
                toast.error(accAvailability.ErrorMessage)
                return true;
            }
            if ([true, "true"].includes(accAvailability.IsAvailable)) {
                const result = fetch(process.env.REACT_APP_BASEURL + 'accounts', {
                    method: 'POST',
                    withCredentials: true,
                    headers: {
                        Authorization:
                            'Bearer ' +
                            JSON.parse(sessionStorage.getItem('user')).signInUserSession
                                .idToken.jwtToken,
                    },
                    body: data,
                });
                result
                    .then((result) => {
                        setLoader(false);
                        result.json().then(async (res) => {
                            if (res.message) {
                                toast.error(res.message);
                            } else {
                                handleReset();
                                toast.success('Account Added');
                                navigate('/account-list')
                            }
                            if (this.state.purge_patient_images_toggle === true) {
                                await this.callPurgeService()
                            }
                        });
                    })
                    .catch((err) => {
                        toast.error(err.message)
                    });
            }
        } else {
            data.append('id', accountData.id);
            data.append('brand_id', accountData.brand_id);
            data.append('parent_id', accountData.parent_id);
            const result = fetch(process.env.REACT_APP_BASEURL + 'accounts', {
                method: 'PUT',
                withCredentials: true,
                headers: {
                    Authorization:
                        'Bearer ' +
                        JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
                            .jwtToken,
                },
                body: data,
            });
            result
                .then((result) => {
                    setLoader(false);
                    result.json().then(async (res) => {
                        if (res.error) {
                            toast.error(res.message);
                        } else {
                            setType('');
                            handleReset();
                            toast.success("Account Updated !!");
                        }
                    });
                })
                .catch((error) => {
                    toast.error(this.props.t('Toast.Error.UpdateAccountDetails') + error.message);
                });
        }
    }

    const handleContactPhone = (e) => {
        const regex = /^[0-9\W]*$/;
        if (regex.test(e.target.value)) {
            setErrors({})
            setPhone(e.target.value);
        } else {
            setErrors({ phone: "Enter valid mobile number" })
        }
    }

    const handleContactPhoneBlur = (e) => {
        let phoneNumber = e.target.value;
        phoneNumber = phoneNumber.replace(/\D/g, '');
        if (phoneNumber.length === 10) {
            phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            setPhone(phoneNumber)
        } else {
            setErrors({ phone: "Enter valid mobile number" })
        }
    }

    const handleBrandPhone = (e) => {
        const regex = /^[0-9\W]*$/;
        if (regex.test(e.target.value)) {
            setErrors({})
            setBrandPhone(e.target.value);
        } else {
            setErrors({ brandPhone: "Enter valid mobile number" })
        }
    }

    const handleBrandPhoneBlur = (e) => {
        let phoneNumber = e.target.value;
        phoneNumber = phoneNumber.replace(/\D/g, '');
        if (phoneNumber.length === 10) {
            phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            setBrandPhone(phoneNumber)
        } else {
            setErrors({ brandPhone: "Enter valid mobile number" })
        }
    }

    const handleAfterHourPhone = (e) => {
        const regex = /^[0-9\W]*$/;
        if (regex.test(e.target.value)) {
            setErrors({})
            setBrandAfterHourPhone(e.target.value);
        } else {
            setErrors({ afterHourPhone: "Enter valid mobile number" })
        }
    }

    const handleAfterHourPhoneBlur = (e) => {
        let phoneNumber = e.target.value;
        phoneNumber = phoneNumber.replace(/\D/g, '');
        if (phoneNumber.length === 10) {
            phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            setBrandAfterHourPhone(phoneNumber)
        } else {
            setErrors({ afterHourPhone: "Enter valid mobile number" })
        }
    }

    const handleAuthentication = async () => {
        setBtnLoader(true)
        Auth.signIn(userName, password).then(async (user) => {
            if (user && user.attributes && user.attributes.email_verified) {
                setIsPurgeFinal(true)
            }
            setBtnLoader(false)
            return
        }).catch(() => {
            toast.error("Enter valid username and password");
            setBtnLoader(false);
            return;
        })
    }

    const handlePurge = () => {
        setIsPurgeFinal(false)
        setIsPurgeAuth(false);
    }


    useEffect(() => {
        getAccounts();
        ConfigureAmplify()
        const TimeZoneNames = moment.tz.names();
        const timeZones = TimeZoneNames.map((items) => {
            const tz = moment().tz(items);
            const findOffset = tz.utcOffset();
            let string = '';
            if (String(findOffset).startsWith('-')) {
                string = `- ${String(findOffset).slice(1)}`;
            } else string = `+ ${String(findOffset)}`;
            return { zone: `(UTC ${string}) ${items}` };
        });
        const zoneOptions = timeZones.map((val) => { return { label: val.zone, value: val.zone } })
        setTimeZoneOpt(zoneOptions)

        if (rowData && rowData.length > 0) {
            let [data] = rowData
            setAccountData(data);
            setAccountName(data.name)
            setAddressLine1(data.contact && data.contact && data.contact.address && data.contact.address.address_line_1);
            setAddressLine2(data.contact && data.contact && data.contact.address && data.contact.address?.address_line_2);
            setCity(data.contact && data.contact && data.contact.address && data.contact.address.city);
            setState(data.contact && data.contact && data.contact.address && data.contact.address.state);
            setZip(data.contact && data.contact && data.contact.address && data.contact.address.zip);
            setFirstName(data.contact && data.contact && data.contact.name.split(' ')[0]);
            setLastName(data.contact && data.contact && data.contact.name.split(' ')[1]);
            setEmail(data.contact && data.contact && data.contact.email);
            setPhone(data.contact && data.contact && maskPhoneNumber(data.contact.phone));
            setAccountImg(data.picture_url);
            setClosedImg(data.hoursof_operations && data.hoursof_operations.closedimg_url);
            setClosedMsg(data.hoursof_operations && data.hoursof_operations.closed_msg);
            setBrandName(data.brand && data.brand.name);
            setBrandPhone(data.brand && maskPhoneNumber(data.brand.phone));
            setBrandWebsite(data.brand && data.brand.website);
            setBrandFax(data.brand && data.brand.fax);
            setBrandEmail(data.brand && data.brand.email);
            setBrandHelp(![undefined, null, 'undefined', 'null'].includes(data.brand.help_link) ? data.brand.help_link : "");
            setBrandAfterHourPhone(data.brand && maskPhoneNumber(data.brand.after_hour_phone));
            setBrandPolicy(![undefined, null, 'undefined', 'null'].includes(data.brand.privacy_policy_link) ? data.brand.privacy_policy_link : "");
            setBrandImg(data.brand && data.brand.logo_url)
            setRxExpDays({ value: data.expiration_days, label: data.expiration_days })
            setTimeZone({ value: data.timezone, label: data.timezone })
            setTimeNotify({ value: data.time_to_notify, label: data.time_to_notify });
            setTaxRate(data.tax_data && data.tax_data.tax_rate);
            setIsPurge(data.purge_patient_images_toggle);
            setRetainDays(data.retain_days);
            setIsDayToRetain([true,'true'].includes(data.is_days_to_retain)?true:false);
            setIsPurge(data.purge_patient_images_toggle);


            const selectedOpt = notifyOptions.find(option => option.value === data.pick_up_reminder_day)
            setPatientNotify(selectedOpt);

            const [_taxCode] = taxOptions.filter((opt) => opt.value === data.tax_data.tax_code)
            setTaxCode(_taxCode)

            const _purgeDay = purgeOption.find(option => option.value === data.purge_patient_images_days_count)
            setPurgeDay(_purgeDay);

            if (data && data.kiosk_hoursof_operations.length > 0) {
                handleKioskHour(data.kiosk_hoursof_operations);
            } else {
                setKioskHoursOfOperaionsArray(kioskWeekArray)
            }

            if (data && data.account_hoursof_operations) {
                const account_hoursof_operations = data.account_hoursof_operations;
                handleAccountHour(account_hoursof_operations);
            } else {
                setAccountHoursOfOperaionsArray(accountHoursOfOperaionsArray);
            }
        }
    }, [])

    return (
        <div className="position-relative">
            {loader ? <div className="loader">
                <Loader />
            </div> : ""}
            <Tab.Container defaultActiveKey={'AccountDetail'}>
                <Nav as='ul' className='nav-tabs'>
                    <Nav.Item as='li' >
                        <Nav.Link eventKey={'AccountDetail'}>
                            Account Detail
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li' >
                        <Nav.Link eventKey={'BrandDetail'}>
                            Brand Detail
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li' >
                        <Nav.Link eventKey={'Setting'}>
                            Settings
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li' >
                        <Nav.Link eventKey={'Privacy'}>
                            Privacy
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li' >
                        <Nav.Link eventKey={'Advanced'}>
                            Advanced
                        </Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content className='pt-4'>
                    {/* Account Details */}
                    <Tab.Pane eventKey={'AccountDetail'} >
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <h3 className="my-3 fw-bold">{type === 'Edit' ? "Edit Account" : "Account"}</h3>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="form-group mb-3">
                                    <label>Parent Account</label>
                                    <Select
                                        value={parentId}
                                        onChange={(val) => setParentId(val)}
                                        options={accountValues}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                        disabled={type === 'View'}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="form-group mb-3">
                                    <label>Account Name</label>
                                    <input
                                        type="text"
                                        value={accountName}
                                        disabled={type === 'View'}
                                        onChange={(e) => setAccountName(e.target.value)}
                                        className="form-control form-control-sm fs-16"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <h3 className="my-3 fw-bold">Account Address</h3>
                            </div>
                            <div className="col-xl-8 col-lg-8">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Address Line 1</label>
                                            <input
                                                type="text"
                                                value={addressLine1}
                                                disabled={type === 'View'}
                                                onChange={(e) => setAddressLine1(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Address Line 2</label>
                                            <input
                                                type="text"
                                                value={addressLine2}
                                                disabled={type === 'View'}
                                                onChange={(e) => setAddressLine2(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                value={city}
                                                disabled={type === 'View'}
                                                onChange={(e) => setCity(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>State</label>
                                            <input
                                                type="text"
                                                value={state}
                                                disabled={type === 'View'}
                                                onChange={(e) => setState(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Zip</label>
                                            <input
                                                type="text"
                                                value={zip}
                                                disabled={type === 'View'}
                                                onChange={(e) => setZip(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 ">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div id='avatarImg' {...getAccountRootProps()}>
                                        {accountImg ? (
                                            <div className='bx-shd'>
                                                <img className="rounded-3" src={accountImg.preview ? URL.createObjectURL(accountImg) : accountImg} height='120px' width='120px' alt="AccountImage" />
                                            </div>
                                        ) : (<div className='bx-shd'> <svg focusable="false" aria-hidden="true" width="100px" height="100px" viewBox="0 0 24 24" data-testid="CloudUploadIcon">
                                            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3" fill="green" />
                                        </svg>
                                        </div>)}
                                    </div>
                                    <label className='pt-1'>Upload Image</label>
                                    <Button variant='info tp-btn btn-sm w-50' onClick={() => handleRemoveImage('account')}>REMOVE IMAGE</Button>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12">
                                <h3 className="my-3 fw-bold">Contact Person</h3>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="form-group mb-3">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        value={firstName}
                                        disabled={type === 'View'}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className="form-control form-control-sm fs-16 "
                                    />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="form-group mb-3">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        value={lastName}
                                        disabled={type === 'View'}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className="form-control form-control-sm fs-16 "
                                    />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="form-group mb-3">
                                    <label>Email Address</label>
                                    <input
                                        type="text"
                                        value={email}
                                        disabled={type === 'View'}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-control form-control-sm fs-16 "
                                    />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input
                                        type="text"
                                        disabled={type === 'View'}
                                        value={phone}
                                        onChange={(e) => handleContactPhone(e)}
                                        onBlur={(e) => handleContactPhoneBlur(e)}
                                        className="form-control form-control-sm fs-16"
                                    />
                                    {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12">
                                <div className="d-flex flex-row my-3">
                                    <Button onClick={() => addContactRow()} className="btn-xs">
                                        <i class="fa-solid fa-plus" />
                                    </Button>
                                    <h3 className="fw-bold ms-2">Add Contact</h3>
                                </div>
                                <div className="my-2">
                                    {accountContactPersons.length > 0 && accountContactPersons.map((val, index) => {
                                        return (
                                            <div key={index} className="row flex justify-content-center align-items-center">
                                                <div className="col-xl-1">
                                                    <Button onClick={() => removeContactRow(index)} className="btn-xs">
                                                        <i class="fa-solid fa-minus" />
                                                    </Button>
                                                </div>
                                                <div className="col-xl-1">
                                                    <div className="form-check custom-checkbox checkbox-info">
                                                        <input
                                                            type="checkbox"
                                                            disabled={type === 'View'}
                                                            checked={[true, 'true'].includes(val.isdefault) ? true : false}
                                                            onChange={(e) => onChangeContactProperty(e, index, 'isdefault')}
                                                            className="form-check-input"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customCheckBox2"
                                                        >
                                                            Active
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2">
                                                    <label>First Name</label>
                                                    <input
                                                        value={val.first_name}
                                                        disabled={type === 'View'}
                                                        onChange={(e) => onChangeContactProperty(e, index, 'first_name')}
                                                        className="form-control form-control-sm fs-16 "
                                                    />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label>Last Name</label>
                                                    <input
                                                        value={val.last_name}
                                                        disabled={type === 'View'}
                                                        onChange={(e) => onChangeContactProperty(e, index, 'last_name')}
                                                        className="form-control form-control-sm fs-16 "
                                                    />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label>Email</label>
                                                    <input
                                                        value={val.email}
                                                        disabled={type === 'View'}
                                                        onChange={(e) => onChangeContactProperty(e, index, 'email')}
                                                        className="form-control form-control-sm fs-16 "
                                                    />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label>Phone</label>
                                                    <input
                                                        maxLength={10}
                                                        disabled={type === 'View'}
                                                        value={maskPhoneNumber(val.phone)}
                                                        onChange={(e) => onChangeContactProperty(e, index, 'phone')}
                                                        className="form-control form-control-sm fs-16 "
                                                    />
                                                </div>
                                                <div className="col-xl-2" />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row gx-6">
                            <div className="col-xl-3 col-lg-3">
                                <h3 className="my-3 fw-bold">Store Business Hours</h3>
                                <div className="row my-2 fw-bold">
                                    <div className="col-4 fw-bold">Days</div>
                                    <div className="col-4 fw-bold">Open-Time</div>
                                    <div className="col-4 fw-bold">Close-Time</div>
                                </div>
                                {accountHoursOfOperaionsArray.map((ele, index) => {
                                    return (
                                        <div className="row mb-3 flex flex-row justify-content-center align-items-center" key={ele.value}>
                                            <div className="col-4">
                                                <h4>{ele.value}</h4>
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    maxLength={5}
                                                    value={timeMask(ele.openTime)}
                                                    disabled={type === 'View'}
                                                    onChange={(e) => handleAccountTimeChange(e, index, 'openTime')}
                                                    className="form-control form-control-sm fs-16"
                                                />
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    maxLength={5}
                                                    value={timeMask(ele.closeTime)}
                                                    disabled={type === 'View'}
                                                    onChange={(e) => handleAccountTimeChange(e, index, 'closeTime')}
                                                    className="form-control form-control-sm fs-16"
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <h3 className="my-3 fw-bold d-flex">Kiosk Business Hours
                                    <div
                                        data-container="body"
                                        data-toggle="popover"
                                        className="pl-2 tooltip1"
                                        title="Set the hours and days your kiosk is open for business."
                                        data-content="Popover body content is set in this attribute."
                                    >
                                        <svg focusable="false" width="25px" height="25px" aria-hidden="true" viewBox="0 0 24 24" data-testid="HelpIcon" aria-label="Set the hours and days your kiosk is open for business.">
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z">
                                            </path>
                                        </svg>
                                    </div>
                                </h3>
                                <div className="row my-2 fw-bold">
                                    <div className="col-1 fw-bold">Days</div>
                                    <div className="col-3 fw-bold text-end">Open-All Day</div>
                                    <div className="col-3 fw-bold">Open-Time</div>
                                    <div className="col-3 fw-bold">Close-Time</div>
                                    <div className="col-2 fw-bold">Closed</div>
                                </div>
                                {kioskHoursOfOperaionsArray.map((ele, index) => {
                                    return (
                                        <div className="row mb-3 flex flex-row justify-content-center align-items-center" key={ele.id}>
                                            <div className="col-2">
                                                <h4>{ele.value}</h4>
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleKioskTimeChange(e, index, 'isOpenAll')}
                                                    disabled={[true, "true"].includes(ele.isClosed) || type === 'View'}
                                                    checked={[true, "true"].includes(ele.isOpenAll) ? true : false}
                                                    className="form-check-input"
                                                />
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    maxLength={5}
                                                    value={timeMask(ele.openTime)}
                                                    onChange={(e) => handleKioskTimeChange(e, index, 'openTime')}
                                                    disabled={
                                                        [true, "true"].includes(ele.isClosed) || [true, "true"].includes(ele.isOpenAll) || type === 'View'
                                                    }
                                                    className="form-control form-control-sm fs-16"
                                                />
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    maxLength={5}
                                                    value={timeMask(ele.closeTime)}
                                                    onChange={(e) => handleKioskTimeChange(e, index, 'closeTime')}
                                                    disabled={
                                                        [true, "true"].includes(ele.isClosed) || [true, "true"].includes(ele.isOpenAll) || type === 'View'
                                                    }
                                                    className="form-control form-control-sm fs-16"

                                                />
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleKioskTimeChange(e, index, 'isClosed')}
                                                    disabled={[true, "true"].includes(ele.isOpenAll) || type === 'View'}
                                                    checked={[true, "true"].includes(ele.isClosed) ? true : false}
                                                    className="form-check-input"
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                                    <div id="closedImg" {...getClosedRootProps()} >
                                        {closedImg ? (
                                            <div className='bx-shd'>
                                                <img className="rounded-3" src={closedImg.preview ? URL.createObjectURL(closedImg) : closedImg} height='120px' width='120px' alt="ClosedImage" />
                                            </div>
                                        ) : (<div className='bx-shd'> <svg focusable="false" aria-hidden="true" width="100px" height="100px" viewBox="0 0 24 24" data-testid="CloudUploadIcon">
                                            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3" fill="green" />
                                        </svg>
                                        </div>)}
                                    </div>
                                    <label className='pt-1'>Upload Closed Image</label>
                                    <Button variant='info tp-btn btn-sm w-50' onClick={() => handleRemoveImage('closed')}>REMOVE IMAGE</Button>
                                    <div className="my-5">
                                        <textarea
                                            value={closedMsg}
                                            className="form-control"
                                            rows="4"
                                            id="comment"
                                            onChange={(e) => setClosedMsg(e.target.value)}
                                            disabled={type === 'View'}
                                            placeholder="Closed Message"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>

                    {/* Brand Details */}
                    <Tab.Pane eventKey={'BrandDetail'} >
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <h3 className="my-3 fw-bold">About Us</h3>
                                <h5 className="my-2">Tell your patients about you.</h5>
                            </div>
                            <div className="col-xl-8 col-lg-8">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Brand Name</label>
                                            <input
                                                type="text"
                                                value={brandName}
                                                disabled={type === "View"}
                                                onChange={(e) => setBrandName(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Phone Number</label>
                                            <input
                                                type="text"
                                                disabled={type === "View"}
                                                value={brandPhone}
                                                onChange={(e) => handleBrandPhone(e)}
                                                onBlur={(e) => handleBrandPhoneBlur(e)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                            {errors.brandPhone && <div className="text-danger fs-12">{errors.brandPhone}</div>}
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Website</label>
                                            <input
                                                type="text"
                                                value={brandWebsite}
                                                disabled={type === "View"}
                                                onChange={(e) => setBrandWebsite(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Fax</label>
                                            <input
                                                type="text"
                                                value={brandFax}
                                                disabled={type === "View"}
                                                onChange={(e) => setBrandFax(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Email Address</label>
                                            <input
                                                type="text"
                                                value={brandEmail}
                                                disabled={type === "View"}
                                                onChange={(e) => setBrandEmail(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Help</label>
                                            <input
                                                type="text"
                                                value={brandHelp}
                                                disabled={type === "View"}
                                                onChange={(e) => setBrandHelp(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Terms And Conditions</label>
                                            <input
                                                type="text"
                                                value={brandTerm}
                                                disabled={type === "View"}
                                                onChange={(e) => setBrandTerm(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>After Hour Phone Number</label>
                                            <input
                                                type="text"
                                                value={brandAfterHourPhone}
                                                disabled={type === "View"}
                                                onChange={(e) => handleAfterHourPhone(e)}
                                                onBlur={(e) => handleAfterHourPhoneBlur(e)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                            {errors.afterHourPhone && <div className="text-danger fs-12">{errors.afterHourPhone}</div>}
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Privacy Policy</label>
                                            <input
                                                type="text"
                                                value={brandPolicy}
                                                disabled={type === "View"}
                                                onChange={(e) => setBrandPolicy(e.target.value)}
                                                className="form-control form-control-sm fs-16 "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 ">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div {...getBrandRootProps()} >
                                        {brandImg ? (
                                            <div className='bx-shd'>
                                                <img id="avatarImg" className="" src={brandImg.preview ? URL.createObjectURL(brandImg) : brandImg} height='100px' width='100px' alt="Selected" />
                                            </div>
                                        ) : (<div className='bx-shd'> <svg focusable="false" aria-hidden="true" width="100px" height="100px" viewBox="0 0 24 24" data-testid="CloudUploadIcon">
                                            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3" fill="green" />
                                        </svg>
                                        </div>)}
                                    </div>
                                    <label className='pt-1'>Upload Image</label>
                                    <Button variant='info tp-btn btn-sm w-50' onClick={() => handleRemoveImage('brand')}>REMOVE IMAGE</Button>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>

                    {/* Settings */}
                    <Tab.Pane eventKey={'Setting'} >
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="form-group mb-3">
                                    <label>Order Expiration Days</label>
                                    <Select
                                        value={rxExpDays}
                                        onChange={(val) => setRxExpDays(val)}
                                        options={expDaysOption}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="form-group mb-3">
                                    <label>Notifications Frequency</label>
                                    <Select
                                        value={patientNotify}
                                        onChange={(val) => setPatientNotify(val)}
                                        options={notifyOptions}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="form-group mb-3">
                                    <label>Time Zone</label>
                                    <Select
                                        value={timeZone}
                                        onChange={(val) => setTimeZone(val)}
                                        options={timeZoneOpt}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="form-group mb-3">
                                    <label>Time to notify</label>
                                    <Select
                                        value={timeNotify}
                                        onChange={(val) => setTimeNotify(val)}
                                        options={timeOptions}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8" />
                        </div>
                    </Tab.Pane>

                    {/* Privacy */}
                    <Tab.Pane eventKey={'Privacy'}>
                        <div className="col-xl-12 col-lg-12">
                            <h3 className="my-3 fw-bold">Purge</h3>
                        </div>
                        <div className='col-xl-4 col-lg-4'>
                            <label>Purge transactions</label>
                            <label className="toggle-switch">
                                <input
                                    type="checkbox"
                                    checked={isPurge}
                                    disabled={type === 'View'}
                                    onChange={(e) => { setIsPurge(e.target.checked); setIsPurgeAuth(e.target.checked) }}
                                    className='form-check-input'
                                />
                                <span className=".form-check-label"></span>
                                <span
                                    data-container="body"
                                    data-toggle="popover"
                                    className="pl-2 tooltip1"
                                    title="This setting will automatically purge all the records prior to configured days. 
                                                It is nonrecoverable, so please consider this action carefully."
                                    data-content="Popover body content is set in this attribute."
                                >
                                    <svg focusable="false" width="25px" height="25px" aria-hidden="true" viewBox="0 0 24 24" data-testid="HelpIcon" aria-label="Set the hours and days your kiosk is open for business.">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z">
                                        </path>
                                    </svg>
                                </span>
                            </label>
                            {isPurge &&
                                <div className="form-group mt-3">
                                    <label>Select days of configuration</label>
                                    <Select
                                        value={purgeDay}
                                        onChange={(val) => setPurgeDay(val)}
                                        options={purgeOption}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            }
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <h3 className="my-3 fw-bold">Anonymize PII</h3>
                        </div>
                        <div className='col-xl-4 col-lg-4'>
                            <label>Days to retain</label>
                            <label className="toggle-switch">
                                <input
                                    type="checkbox"
                                    checked={isDayToRetain}
                                    disabled={type === 'View'}
                                    onChange={(e) => setIsDayToRetain(e.target.checked)}
                                    className='form-check-input'
                                />
                                <span className=".form-check-label"></span>
                                <span
                                    data-container="body"
                                    data-toggle="popover"
                                    className="pl-2 tooltip1"
                                    title="Turning this feature on will anonymize the data after the number of days you input. This function is non-recoverable."
                                    data-content="Popover body content is set in this attribute."
                                >
                                    <svg focusable="false" width="25px" height="25px" aria-hidden="true" viewBox="0 0 24 24" data-testid="HelpIcon" aria-label="Set the hours and days your kiosk is open for business.">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z">
                                        </path>
                                    </svg>
                                </span>
                            </label>
                            {isDayToRetain &&
                                <div className="form-group mt-3">
                                    <label>How long do we retain this information?</label>
                                    <input
                                        type="number"
                                        value={retainDays}
                                        disabled={type === 'View'}
                                        onChange={(e) => setRetainDays(e.target.value)}
                                        className="form-control form-control-sm fs-16"
                                    />
                                </div>
                            }
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'Advanced'} >
                        <div>
                            <Accordion className="accordion accordion-left-indicator" defaultActiveKey="1">
                                <Accordion.Item>
                                    <Accordion.Header className="accordion-header">
                                        Products
                                    </Accordion.Header>
                                    <Accordion.Collapse>
                                        <div className="accordion-body">Products</div>
                                    </Accordion.Collapse>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion className="accordion accordion-left-indicator" defaultActiveKey="1">
                                <Accordion.Item>
                                    <Accordion.Header className="accordion-header">
                                        Taxes
                                    </Accordion.Header>
                                    <Accordion.Collapse>
                                        <div className="accordion-body">
                                            <div className='card'>
                                                <div className="card-header">
                                                    <h4 className="card-title">Tax</h4>
                                                </div>
                                                <div className="card-body d-flex">
                                                    <div className='col-2'>
                                                        <label>Tax Code</label>
                                                        <Select
                                                            value={taxCode}
                                                            onChange={(val) => setTaxCode(val)}
                                                            options={taxOptions}
                                                            style={{
                                                                lineHeight: '40px',
                                                                color: '#7e7e7e',
                                                                paddingLeft: ' 15px',
                                                            }} />
                                                    </div>
                                                    <div className='col-4 ml-3'>
                                                        <label>Rate</label>
                                                        <input
                                                            type="text"
                                                            value={taxRate}
                                                            disabled={type === 'View'}
                                                            onChange={(e) => setTaxRate(e.target.value)}
                                                            className="form-control form-control-sm"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
                <div className="col-xl-12 col-lg-12 mt-2">
                    <div className="d-flex flex-row justify-content-end">
                        <Button onClick={() => ['View', 'Edit'].includes(type) ? setType('') : navigate('/account-list')} variant='primary btn-sm mx-2'>Cancel</Button>
                        {type !== 'View' ?
                            <Button onClick={() => handleSubmit()} variant='primary btn-sm mx-2'>{type === 'Edit' ? "Update Account" : "Add Account"}</Button> : ''}
                    </div>
                </div>
            </Tab.Container>
            <Modal className="fade" show={isPurgeAuth && isPurge} centered>
                <Modal.Header>
                    <Modal.Title>This action is non-recoverable. Are you sure you want to do this? Please enter your username and password to proceed.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mb-3">
                        <label>Username</label>
                        <input
                            type="email"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            className="form-control form-control-sm fs-16 "
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control form-control-sm fs-16 "
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn-sm"
                        variant="success"
                        disabled={btnLoader}
                        onClick={() => handleAuthentication()}
                    >
                        {btnLoader ? <BtnLoader /> : 'Yes'}
                    </Button>
                    <Button
                        className="btn-sm"
                        disabled={btnLoader}
                        variant="danger"
                        onClick={() => { setIsPurgeAuth(false); setIsPurge(false) }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal className="fade" size="sm" show={isPurgeFinal} centered>
                <Modal.Header>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button
                        className="btn-sm"
                        variant="success"
                        onClick={() => handlePurge()}
                    >
                        Yes
                    </Button>
                    <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => { setIsPurge(false); setIsPurgeFinal(false); setIsPurgeAuth(false) }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Account;
