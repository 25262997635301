import React, { useEffect, useState } from "react";
import Select from "react-select";
import request from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/bootstrap/Loader";
import {
  Tab,
  Nav,
  Button,
} from "react-bootstrap";
import ManageGroupUsersList from "./ManageGroupUsersList";

const ManageGroups = ({ rowData, type, setType }) => {
  const [groupName, setGroupName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [accountValues, setAccountValues] = useState([]);
  const [parentId, setParentId] = useState("");
  const [groupType, setGroupType] = useState([]);
  const [groupTypeId, setGroupTypeId] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const getAccounts = async () => {
    const accResData = await request("get", "accounts");
    if (accResData.message) {
      toast.error(accResData.message);
      return;
    }
    const accountValues = accResData.map((account) => {
      return { value: account.id, label: account.name };
    });
    setAccountValues(accountValues);
    setParentId(accountValues[0]);
  };

  const getGroupTypes = async () => {
    const groupTypesResponse = await request("get", "groups/list/types");
    if (groupTypesResponse.status !== 200) {
      toast.error(groupTypesResponse.message);
      return;
    }
    const groupTypeValues = groupTypesResponse?.data?.map((gt) => {
      return { value: gt.id, label: gt.type };
    });
    setGroupType(groupTypeValues);
    setGroupTypeId(groupTypeValues[0]);
  };

  useEffect(() => {
    getAccounts();
    getGroupTypes();
  }, []);

  useEffect(() => {
    if (rowData && rowData.length > 0) {
      let [data] = rowData;
      setGroupName(data?.name);
      const selectedParent = accountValues?.filter(
        (x) => x.value === data?.parentAccount?.id
      )[0];
      setParentId(selectedParent);
      const selectedType = groupType?.filter(
        (x) => x.value === data?.type?.id
      )[0];
      setGroupTypeId(selectedType);
      setIsActive(data?.is_active);
    }
  }, [accountValues, groupType, rowData]);

  const handleClear = () => {
    setGroupName("");
    setIsActive(false);
    setAccountValues([]);
    setParentId("");
    setGroupType([]);
    setGroupTypeId("");
    setLoader(false);
  };

  const handleSubmit = async () => {
    setLoader(true);
    let user = JSON.parse(sessionStorage.getItem("user")).username;
    let payload = {
      name: groupName,
      type_id: groupTypeId?.value,
      parent_account_id: parentId?.value,
      user_id: user,
      is_active: isActive,
    };
    if (!["View", "Edit"].includes(type)) {
      const result = fetch(process.env.REACT_APP_BASEURL + "groups", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
              .jwtToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      result
        .then((result) => {
          result.json().then((res) => {
            setLoader(false);
            if (res.status === 201) {
              handleClear();
              toast.success("Group created successfully!");
              navigate("/manage-groups");
            } else {
              toast.error(res.message);
              return;
            }
          });
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.message);
        });
    } else {
      let [data] = rowData;
      const result = fetch(
        `${process.env.REACT_APP_BASEURL}groups?id=${data.id}`,
        {
          method: "PUT",
          withCredentials: true,
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(sessionStorage.getItem("user")).signInUserSession
                .idToken.jwtToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      result
        .then((result) => {
          result.json().then((res) => {
            setLoader(false);
            if (res.error) {
              toast.error(res.error.message);
              return;
            } else {
              setType("");
              handleClear();
              toast.success("Group updated successfully!");
              navigate("/manage-groups");
            }
          });
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.message);
        });
    }
  };

  return (
    <div className="position-relative">
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <Tab.Container defaultActiveKey="GROUPDETAILS">
        <Nav as="ul" className="nav-tabs">
          <Nav.Item as="li">
            <Nav.Link eventKey={"GROUPDETAILS"}>GROUP DETAILS</Nav.Link>
          </Nav.Item>
          {type === "Edit" || type === "View" ? (
            <Nav.Item as="li">
              <Nav.Link eventKey={"USERS"}>USERS</Nav.Link>
            </Nav.Item>
          ) : (
            ""
          )}
        </Nav>
        <Tab.Content className="pt-4">
          {/* USER DETAILS */}
          <Tab.Pane eventKey="GROUPDETAILS">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <h4 className="my-3 fw-bold">
                  {type === "View"
                    ? "Group"
                    : type === "Edit"
                    ? "Edit Group"
                    : "Group"}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4">
                <div className="form-group mb-3">
                  <label>Group Name</label>
                  <input
                    type="text"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    className="form-control  form-control-sm fs-16"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="form-group mb-3">
                  <label>Parent Account</label>
                  <Select
                    value={parentId}
                    onChange={(val) => setParentId(val)}
                    options={accountValues}
                    style={{
                      lineHeight: "40px",
                      color: "#7e7e7e",
                      paddingLeft: " 15px",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="form-group mb-3">
                  <label>Group Type</label>
                  <Select
                    value={groupTypeId}
                    onChange={(val) => setGroupTypeId(val)}
                    options={groupType}
                    style={{
                      lineHeight: "40px",
                      color: "#7e7e7e",
                      paddingLeft: " 15px",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <label>Active</label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                    className="form-check-input mx-4"
                  />
                  <span className=".form-check-label"></span>
                </label>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-end">
                <Button
                  onClick={() =>
                    ["View", "Edit"].includes(type)
                      ? setType("")
                      : navigate("/manage-groups")
                  }
                  className="me-2 btn-sm"
                >
                  Cancel
                </Button>
                {type !== "View" ? (
                  <Button
                    onClick={() => handleSubmit()}
                    className="me-2 btn-sm"
                    disabled={type === "View"}
                  >
                    {type === "Edit" ? "Update" : "Add"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="USERS">
            <ManageGroupUsersList
              rowData={rowData}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ManageGroups;
