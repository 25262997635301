import React, { useEffect, useState } from 'react';
import request from './../../../services/AxiosInstance';
import Select from 'react-select';
import { Loader } from '../../components/bootstrap/Loader';
import { toast } from 'react-toastify';
import { Button, Form, FormControl, ListGroup } from 'react-bootstrap'

const UserRoles = () => {
    const [storeData, setStoreData] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [parentAccount, setParentAccount] = useState(null);
    const [roleSelect, setRoleSelect] = useState(null);
    const [loader, setLoader] = useState(false);
    const [permissionData, setPermissionData] = useState([]);
    const [checkedPermissionSubCategory, setCheckedPermissionSubCategory] = useState([]);
    const [uniquePermissionCategories, setUniquePermissionCategories] = useState([]);
    const [checkedPermissionParentCategory, setCheckedPermissionParentCategory] = useState([]);
    const [accountId, setAccountId] = useState('')
    const [query, setQuery] = useState('');
    const [queryId, setQueryId] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAccount = async () => {
        setLoader(true);
        const account = await request('get', 'accounts');
        if (account.message) {
            toast.error(account.message);
            setLoader(false);
            return;
        }
        setAccountId(account[0].id)
        const storeData = account.map(item => ({ value: item.id, label: item.name }));
        setStoreData(storeData);

        const getRoles = await request('get', 'roles');
        if (getRoles.message) {
            toast.error(getRoles.message);
            setLoader(false);
            return;
        }
        const rolesData = getRoles.map(item => ({ value: item.id, label: item.name }));
        setRolesData(rolesData);
        setLoader(false);
    };

    const handlePrAccount = (val) => {
        setParentAccount(val);
    };

    const handleRole = async (val) => {
        setRoleSelect(val);
        if (parentAccount) {
            setLoader(true);
            const obj = {
                accountid: parentAccount.value,
                roleid: val.value,
                group_id: queryId
            };
            const getRoles = await request('post', 'permissions', obj);
            if (getRoles) {
                setPermissionData(getRoles);
                const uniqueCategories = getUnique(getRoles, 'entity_type_id');
                setUniquePermissionCategories(uniqueCategories);

                const parentArray = uniqueCategories.map((item) => ({
                    value: item.entity_type_id,
                    label: item.entity_name,
                    status: item.status,
                }));
                setCheckedPermissionParentCategory(parentArray);

                const subArray = getRoles.map((item) => ({
                    parentvalue: item.entity_type_id,
                    parentLabel: item.entity_name,
                    value: item.id,
                    label: item.name,
                    status: item.status,
                }));
                setCheckedPermissionSubCategory(subArray);
                setLoader(false);
            }
        }
    };

    const getUnique = (arr, comp) => {
        const unique = arr
            .map((e) => e[comp])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((e) => arr[e])
            .map((e) => arr[e]);
        return unique;
    };

    const parentCheckPermission = (id) => {
        const updatedParentCategory = checkedPermissionParentCategory.map((item) => {
            if (item.value === id) {
                return { ...item, status: !item.status };
            }
            return item;
        });

        const obj1 = updatedParentCategory.find((obj) => obj.value === id);

        const updatedSubCategory = checkedPermissionSubCategory.map((item) => {
            if (item.parentvalue === id) {
                return { ...item, status: obj1.status };
            }
            return item;
        });

        setCheckedPermissionParentCategory(updatedParentCategory);
        setCheckedPermissionSubCategory(updatedSubCategory);
    };

    const checkedPermission = (elemId) => {
        const updatedSubCategory = checkedPermissionSubCategory.map((item) => {
            if (item.value === elemId) {
                return { ...item, status: !item.status };
            }
            return item;
        });

        const obj1 = updatedSubCategory.find((obj) => obj.value === elemId);

        const updatedParentCategory = checkedPermissionParentCategory.map((item) => {
            if (item.value === obj1.parentvalue && !obj1.status) {
                return { ...item, status: false };
            }
            return item;
        });

        const obj2 = updatedSubCategory.filter((obj) => obj.parentvalue === obj1.parentvalue);
        if (obj2 !== undefined) {
            const allChecked = obj2.every((item) => item.status);
            const updatedParentCategoryFinal = updatedParentCategory.map((parentItem) => {
                if (parentItem.value === obj1.parentvalue) {
                    return { ...parentItem, status: allChecked };
                }
                return parentItem;
            });
            setCheckedPermissionParentCategory(updatedParentCategoryFinal);
        }

        setCheckedPermissionSubCategory(updatedSubCategory);
    };

    const checkValueOfSubCategory = (id) => {
        const obj = checkedPermissionSubCategory.find((obj) => obj.value === id);
        return obj ? obj.status : false;
    };

    const checkValueOfCategory = (id) => {
        const obj = checkedPermissionParentCategory.find((obj) => obj.value === id);
        return obj ? obj.status : false;
    };

    const handleSave = async () => {
        setLoader(true)
        const rolesObj = {
            account_id: accountId,
            role_id: roleSelect.value,
            permissions: checkedPermissionSubCategory,
            group_id: queryId
        };
        const resPermission = await request('post', 'rolepermissions', rolesObj)
        if (resPermission.message) {
            toast.error(resPermission.message)
            setLoader(false);
            return;
        }
        toast.success("Permission set successfully.")
        setLoader(false);
    }

    const renderPermissions = () => {
        return (
            <>
                <div className='row'>
                    <h3 className="my-3 fw-bold">Permissions</h3>
                    {uniquePermissionCategories.map((items) => (
                        <div className="row mb-2 flex flex-row" key={items.entity_type_id}>
                            <div className='row'>
                                <div className='col-4'>
                                    <input
                                        type="checkbox"
                                        value={items.entity_name}
                                        checked={checkValueOfCategory(items.entity_type_id)}
                                        onChange={() => parentCheckPermission(items.entity_type_id)}
                                        className="form-check-input mr-2"
                                    />
                                    <label>{items.entity_name}</label>
                                </div>
                            </div>
                            <div className='row ml-3'>
                                {permissionData.filter((item) => item.entity_type_id === items.entity_type_id).map((elem) => (
                                    <div className='col-3 mt-2' key={elem.id}>
                                        <input
                                            type="checkbox"
                                            checked={checkValueOfSubCategory(elem.id)}
                                            onChange={() => checkedPermission(elem.id)}
                                            value={elem.name}
                                            className="form-check-input mr-2"
                                        />
                                        <label>{elem.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='text-end'>
                    <Button onClick={() => handleSave()} className='fs-12 btn-sm text-black me-2'>Save</Button>
                </div>
            </>
        );
    };

    useEffect(() => {
        getAccount();
    }, []);

    useEffect(() => {
        if (query && query.length > 0) {
          setIsLoading(true);
          if(query.length > 3) {
              fetchSuggestions(query);
          }
        } else {
          setSuggestions([]);
        }
      }, [query]);
  
      const fetchSuggestions = async (query) => {
        try {
          const parentAccountId = sessionStorage.getItem('parentAccountId');
          if (![undefined, undefined, null, null, ''].includes(parentAccountId)) {
              const resLocation = await request('get', `groups/search?searchText=${query}&parent_account_id=${parentAccountId}`)
              const data = await resLocation?.data
              setSuggestions(data);
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        } finally {
          setIsLoading(false);
        }
      };

    const handleInputChange = (event) => {
        setQuery(event.target.value);
      };
  
      const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion.name);
        setQueryId(suggestion.id);
        setSuggestions([]);
      };

    return (
      <div className="position-relative">
        {loader && (
          <div className="loader">
            <Loader />
          </div>
        )}
        <div className="row">
          <div className="row">
            <div className="col-xl-4 col-lg-4">
              <div className="form-group mb-3">
                <label>Parent Account</label>
                <Select
                  value={parentAccount}
                  options={storeData}
                  onChange={handlePrAccount}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div>
                <Form inline>
                  <label>Group</label>
                  <FormControl
                    style={{ height: "40px" }}
                    type="text"
                    placeholder="Select Group"
                    className="mr-sm-2"
                    value={query}
                    onChange={handleInputChange}
                  />
                </Form>

                {isLoading && <div>Loading...</div>}

                {suggestions.length > 0 && (
                  <ListGroup
                    style={{
                      zIndex: 1,
                      width: "26.5%",
                      position: "absolute",
                      background: "#fff",
                    }}
                  >
                    {suggestions.map((suggestion, index) => (
                      <ListGroup.Item
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.name}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="form-group mb-3">
                <label>Select Role</label>
                <Select
                  value={roleSelect}
                  options={rolesData}
                  onChange={handleRole}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                />
              </div>
            </div>
          </div>
          {uniquePermissionCategories.length > 0 && renderPermissions()}
        </div>
      </div>
    );
};

export default UserRoles;
