const Legend = [
    {
        color: "linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)",
        label: "Available"
    },
    {
        color: "linear-gradient(155deg, rgb(212,209,0) 0%, rgb(255,255,189) 90%)",
        label: "Assigned",
    },
    {
        color:
            "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)",
        label: "Hold",
    },
    {
        color:
            "linear-gradient(155deg, rgb(23,166,0) 0%, rgb(196,255,189) 90%)",
        label: "Stocked",
    },
    {
        color:
            "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)",
        label: "Deactivate",
    },
];

export default Legend; 