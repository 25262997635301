import React from "react";
import packageJson from "../../../package.json"

const Footer = () => {
  const dat = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © 2018 - {dat.getFullYear()}{" "}
          <a href="https://ilocalbox.com/" target="new">
            iLocalbox.com
          </a>{" "}
          All rights reserved.
          <span style={{width:"100%" ,textAlign: "end",marginLeft:"2rem" }}>
            v{packageJson.version}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
