import React, { Fragment, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import SelectBin from "./select-bin";
import VerifyOrder from "./verify-order";
import PlaceOrder from "./place-order";

const OrderWizard = ({
  binIds,
  parentaccountId,
  handleEventLog,
  getBoxes,
  pharmacyDetails,
  boxDetails,
  accountDetails,
  generateCodes,
  setShowWizard,
  handleInvoiceCreate
}) => {
  const [goSteps, setGoSteps] = useState(0);
  const [selectedBin, setSelectedBin] = useState();
  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="form-wizard ">
            <Stepper
              className="nav-wizard"
              style={{ padding: "0px" }}
              activeStep={goSteps}
              label={false}
            >
              <Step
                className="nav-link"
                onClick={() => setGoSteps(0)}
                label="Select bin"
              />
              <Step
                className="nav-link"
                onClick={() => setGoSteps(1)}
                label="Verify item"
              />
              <Step
                className="nav-link"
                onClick={() => setGoSteps(2)}
                label="Add new item"
              />
            </Stepper>
            {goSteps === 0 && (
              <>
                <SelectBin
                  binIds={binIds}
                  setSelectedBin={setSelectedBin}
                  selectedBin={selectedBin}
                />
                <div className="text-end toolbar toolbar-bottom p-2">
                  <button
                    className="btn btn-primary sw-btn-next"
                    onClick={() => setGoSteps(1)}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
            {goSteps === 1 && (
              <>
                <VerifyOrder selectedBin={selectedBin} />
                <div className="text-end toolbar toolbar-bottom p-2">
                  <button
                    className="btn btn-secondary sw-btn-prev me-1"
                    onClick={() => setGoSteps(0)}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-primary sw-btn-next ms-1"
                    onClick={() => setGoSteps(2)}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
            {goSteps === 2 && (
              <PlaceOrder
                selectedBin={selectedBin}
                parentaccountId={parentaccountId}
                handleEventLog={handleEventLog}
                getBoxes={getBoxes}
                pharmacyDetails={pharmacyDetails}
                boxDetails={boxDetails}
                accountDetails={accountDetails}
                generateCodes={generateCodes}
                setGoSteps={setGoSteps}
                setShowWizard={setShowWizard}
                handleInvoiceCreate={handleInvoiceCreate}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderWizard;
