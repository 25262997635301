import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoutLink from './Logout';
import { Button, Dropdown, Modal } from "react-bootstrap";
import request from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import logo from "../../../images/ilocalboxlogo.png";
import { Auth } from "aws-amplify";
import { sha256 } from 'js-sha256';
import { BtnLoader } from "../../components/bootstrap/Loader";
import ConfigureAmplify from "../../../common/awsConfig ";
import CryptoJS from 'crypto-js';

const Header = () => {
   const location = useLocation();
   const [userData, setUserData] = useState('');
   const [finalName, setFinalName] = useState('');
   const [passwordModal, setPasswordModal] = useState(false);
   const [currentPassword, setCurrentPassword] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [errors, setErrors] = useState({});
   const [loader, setLoader] = useState(false);
   const [badgeModal, setBadgeModal] = useState(false);
   const [badgePassword, setBadgePassword] = useState('');
   const [badgeError, setBadgeError] = useState(false);
   const [badgeLoader, setBadgeLoader] = useState(false);
   const passwordRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;
   const secretKey = process.env.REACT_APP_CIPHER;

   const handleValidation = () => {
      const newErrors = {};

      if (!currentPassword) {
         newErrors.currentPassword = "Current Password is required";
      }
      if (!newPassword) {
         newErrors.newPassword = "Password is required";
      } else if (!passwordRegex.test(newPassword)) {
         newErrors.newPassword = "Password must be 8 characters or more, with at least one uppercase letter, one lowercase letter, one digit, and one special character.";
      }
      if (!confirmPassword) {
         newErrors.confirmPassword = "Confirm Password is required";
      } else if (newPassword !== confirmPassword) {
         newErrors.confirmPassword = "Confirm Password and Password should be the same.";
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
   };

   const getUserData = async () => {
      try {
         const userName = JSON.parse(sessionStorage.getItem('user')).username;
         const resUser = await request('get', `users/${userName}`);
         if (resUser.message) {
            toast.error(resUser.message);
            return;
         }
         setUserData(resUser);
      } catch (error) {
         toast.error("Failed to fetch user data");
      }
   };

   const handleReset = () => {
      setNewPassword('');
      setCurrentPassword('');
      setConfirmPassword('')
   }

   const handleChangePassword = async () => {
      if (handleValidation()) {
         setErrors({});
         setLoader(true)
         let user_id = JSON.parse(sessionStorage.getItem('user')).username;
         let pw_hash = sha256(`${newPassword} ${user_id}`);
         let passwordObj = {
            id: user_id,
            password: pw_hash,
         };
         const lastPasswordRes = await request('post', 'getLastPasswords/', passwordObj)

         if (lastPasswordRes.message) {
            toast.error(lastPasswordRes.message);
            setLoader(false);
            handleReset();
            return;
         }
         let current_password_hash = sha256(`${currentPassword}`);
         let new_password_hash = sha256(`${newPassword}`);
         if ([true, 'true'].includes(lastPasswordRes.res)) {
            toast.error('Password already exists.')
            setLoader(false);
            handleReset();
            return;
         } else if (current_password_hash === new_password_hash) {
            toast.error('Password already exists.')
            setLoader(false);
            handleReset();
            return;
         } else {
            const user = JSON.parse(sessionStorage.getItem('user')).username
            Auth.signIn(user, currentPassword)
               .then((updatedUser) => {
                  Auth.changePassword(updatedUser, currentPassword, newPassword)
                     .then(async (result) => {
                        debugger
                        if (result === 'SUCCESS') {
                           toast.success('Password reset successfully!');
                           setLoader(false);
                           handleReset();
                           setPasswordModal(false);
                        }
                        const pw_obj = {
                           userid: JSON.parse(sessionStorage.getItem('user')).username,
                           password: pw_hash,
                        };
                        const savepasswordRes = await request('post', 'savepassword', pw_obj);
                        if (savepasswordRes.message) {
                           toast.error(savepasswordRes.message)
                           setLoader(false);
                           handleReset();
                           return;
                        }

                        delete pw_obj.password;
                        const updatedRes = await request('post', 'UpdateResetPWDate', pw_obj)
                        if (updatedRes.message) {
                           toast.error(updatedRes.message)
                           setLoader(false);
                           handleReset();
                           return;
                        }
                     })
                     .catch((reason) => {
                        toast.error(reason.message)
                        setLoader(false);
                        handleReset();
                        return;
                     });
               })
               .catch((reason) => {
                  toast.error(reason.message)
                  setLoader(false);
                  handleReset();
                  return;
               });
         }
      }
   };

   const handleSecureBadge = () => {
      setBadgeLoader(true);
      if (badgePassword.length <= 0) {
         setBadgeError(true);
         setBadgeLoader(false);
         return;
      }

      Auth.signIn(JSON.parse(sessionStorage.getItem('user')).username, badgePassword)
         .then(async () => {
            const badgeObj = { verifiedPassForSecureBadge: CryptoJS.AES.encrypt(JSON.stringify(badgePassword), secretKey,).toString(), isRetail: true }
            const badgeRes = await request('post', 'generate/secure/badge', badgeObj)
            if (badgeRes.message) {
               if (badgeRes.success) {
                  toast.success(badgeRes.message)
               } else {
                  toast.error(badgeRes.message)
               }
               setBadgeLoader(false);
               setBadgePassword('');
               setBadgeModal(false);
               return;
            }
            setBadgeLoader(false);
            setBadgePassword('');
            setBadgeModal(false);
            return;
         })
         .catch((reason) => {
            setBadgeLoader(false);
            toast.error(reason.message.includes('Incorrect username or password.') ? reason.message : 'Something went wrong!')
         });
   }

   useEffect(() => {
      const updateFinalName = () => {
         const path = location.pathname.split("/");
         const name = path[path.length - 1].split("-");
         const filterName = name.length >= 3 ? name.filter((_, i) => i > 0) : name;
         const joinedName = filterName.join(" ")
         setFinalName(joinedName === 'pickedup items' ? "Picked Up Items" : joinedName);
      };

      updateFinalName();
   }, [location.pathname]);

   useEffect(() => {
      getUserData();
      ConfigureAmplify();
   }, []);

   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div className="dashboard_bar" style={{ textTransform: "capitalize" }}>
                        {finalName}
                     </div>
                  </div>
                  <ul className="navbar-nav header-right">
                     <Dropdown as="li" className={`nav-item header-profile`}>
                        <Dropdown.Toggle className="nav-link i-false" as="a">
                           <img src={userData && userData[0]?.picture_url ? userData[0].picture_url : logo} width={20} alt="" />
                           <div className="header-info">
                              <span>
                                 <h6 className="my-1 text-primary fw-bold">
                                    {JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken.payload.name}
                                 </h6>
                                 <h6>{sessionStorage.getItem("parentAccountName")}</h6>
                                 <h6>{sessionStorage.getItem("groupName")}</h6>
                              </span>
                           </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'end'} className={`dropdown-menu-right`}>
                           <Link onClick={() => setPasswordModal(true)} className="dropdown-item ai-icon">
                              <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                                 <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                 <circle cx={12} cy={7} r={4} />
                              </svg>
                              <span className="ms-2">Change Password</span>
                           </Link>
                           <Link onClick={() => setBadgeModal(true)} className="dropdown-item ai-icon">
                              <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                 <polyline points="22,6 12,13 2,6" />
                              </svg>
                              <span className="ms-2">Create Secure Badge</span>
                           </Link>
                           <LogoutLink />
                        </Dropdown.Menu>
                     </Dropdown>
                  </ul>
               </div>
            </nav>
         </div>
         <Modal className="fade" show={passwordModal} centered>
            <Modal.Header>
               <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <h5>The password should be at least 8 characters long
                  and should include one uppercase letter,
                  one lowercase letter, and one special symbol.
                  Password cannot be one of the last 15 you have used.</h5>
               <div className='form-group'>
                  <label className='mb-1 '>
                     <strong>Current Password</strong>
                  </label>
                  <input type="password" className="form-control" value={currentPassword} autoFocus onChange={(e) => setCurrentPassword(e.target.value)} />
                  {errors.currentPassword && <div className="text-danger fs-12">{errors.currentPassword}</div>}
               </div>
               <div className='form-group'>
                  <label className='mb-1 '>
                     <strong>New Password</strong>
                  </label>
                  <input type="password" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  {errors.newPassword && <div className="text-danger fs-12">{errors.newPassword}</div>}
               </div>
               <div className='form-group'>
                  <label className='mb-1 '>
                     <strong>Confirm New Password</strong>
                  </label>
                  <input type="password" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  {errors.confirmPassword && <div className="text-danger fs-12">{errors.confirmPassword}</div>}
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Button className="btn-sm" variant="danger light" disabled={loader} onClick={() => setPasswordModal(false)}>
                  Close
               </Button>
               <Button onClick={() => handleChangePassword()} className="btn-sm btn-primary">
                  {loader ? <BtnLoader /> : "Change Password"}
               </Button>
            </Modal.Footer>
         </Modal>
         <Modal className="fade" show={badgeModal} centered>
            <Modal.Header>
               <Modal.Title>Create Secure Badge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='form-group'>
                  <label className='mb-1 '>
                     <strong>Enter Password</strong>
                  </label>
                  <input type="password" className="form-control" value={badgePassword} autoFocus onChange={(e) => setBadgePassword(e.target.value)} />
                  {badgeError && <div className="text-danger fs-12">Incorrect password</div>}
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Button className="btn-sm" variant="danger light" disabled={badgeLoader} onClick={() => setBadgeModal(false)}>
                  Close
               </Button>
               <Button onClick={() => handleSecureBadge()} className="btn-sm btn-primary">
                  {badgeLoader ? <BtnLoader /> : "Create Secure Badge"}
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default Header;
